import React, { useEffect } from 'react';
import { Container, Body, Content, Footer, Header } from './styles';
import { useModal } from '../../../hooks/Modal';


interface IModalProps {
  title: string;
  key: string;
  content: JSX.Element;
  className: string;
  closeButton?: string
}

const ModalContainer: React.FC<IModalProps> = ({
  key,
  content,
  title = '',
  className = '',
  closeButton = 'yes'
}) => {
  const { removeModal, messages } = useModal();

  // Depende de removeModal e key para garantir que temos as referências corretas


  return (
    <Container >
      <Body className={className}>
        <Header>{title}</Header>
        <Content>{content}</Content>
        {closeButton === 'yes' ? <Footer>
          <button type="button" onClick={() => removeModal(key)}>
            Fechar
          </button>
        </Footer> : <></>}
      </Body>
    </Container>
  );
};

export default ModalContainer;
