import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Container = styled(animated.div)`
  background: rgba(0, 0, 0, 0.3) !important;
  width: 100%;
  min-height: 100vh;
  position: relative;
  border-radius: 10px;
  box-shadow: 2px 2px 8px #0002;
  display: flex;
  justify-content: center;
  align-items: center;

  .defaultButton {
     
      background: #888;
      color: white;
      width: 800px;
      padding: 20px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      border: 0;
      border-radius: 8px;

      &:hover {
        opacity: 0.9;
      }

      > svg {
        margin-right: 15px;
      }
    }
  

    .whiteModal {
    background : #fff !important;
    width: 90% !important;
 
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
 

    >h2{
      color: #333;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 800px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }


  .whiteModalCapa {
    background : #fff !important;
    width: 100% !important;
    max-width: 760px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,100,0.7);
 

    >h2{
      color: #333;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 800px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }

  .whiteModal2 {
    background : #fff !important;
    width: 100% !important;
    max-width: 560px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,100,0.7);
 

    >h2{
      color: #333;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 800px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }

  .whiteModalMedium {
    background : #fff !important;
    width: 100% !important;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,100,0.7);
 

    >h2{
      color: #333;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 800px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }

  .blackModal {
    background : #333 !important;
    width: 100% !important;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.9);
 

    >h2{
      color: #fff;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 400px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }

  .blackModalMedium {
    background : #333 !important;
    width: 100% !important;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.9);
 

    >h2{
      color: #fff;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 400px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }

 
    width: 100% !important;
    padding: 0px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0,0,150,0.7);
 

    >h2{
      color: #333;
    }
 

    >div {
      width: 100%;
      //max-width: 800px;
      
      >form {
        width: 100%;

        >div{
          box-shadow: none !important;
          border: 0px !important;
          padding: 10px;
        }
      }
    }

    @media (max-width: 800px) {
    max-width: 100%;
    padding: 20px 5px;
  }
  }
`;

export const Body = styled(animated.div)`
  background: rgba(0, 0, 0, 0.9);
  max-width: 90%;
  justify-content: center;
  padding: 40px 30px;
  animation: ${fadeFromLeft} 1s;
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (max-width: 750px) {
    max-width: 100%;
    padding: 20px 5px;
  }
`;

export const Content = styled(animated.div)`
  width: 100%;
  max-height: 80vh;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Footer = styled(animated.div)`
  width: 100%;
  min-height: 44px;
  padding: 10px;
  text-align: center;

  > button {
    background: #888;
    color: white;
    width: 200px;
    padding: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 8px;
    margin: auto;

    &:hover {
      opacity: 0.9;
    }

    > svg {
      margin-right: 15px;
    }
  }
`;

export const Header = styled.h2`
  width: 100%;

  padding: 10px;
`;
