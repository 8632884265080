import React from 'react';

const LeiteEmPo = ({ style }: Record<string, any>) => {

    return <div className="row-to-column" style={{ alignItems: 'center' }}><p className='  hugeTitle2  max-600  full-width  ' style={{ textAlign: 'left', ...style }}>
        <strong className='colorWhite'>NOVO </strong><strong className='color2'> LEITE EM PÓ</strong>
    </p>
        <img style={{ width: '120px', marginTop: '-10px' }} src="/apoio/ccgl-leite.png" />
    </div>

}

export default LeiteEmPo;