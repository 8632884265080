import React from 'react';
export const ProteinaSaudeOssea = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    PROTEÍNA</h2>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>    DESENVOLVIMENTO ÓSSEO DURANTE O CRESCIMENTO</h2>
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}> Durante o crescimento, especialmente na infância e adolescência, a ingestão adequada de proteínas é essencial para o desenvolvimento ósseo ideal. Estudos mostram que a proteína fornece os aminoácidos necessários para a formação e o crescimento do tecido ósseo, ajudando a atingir a densidade mineral óssea desejada.
        </p>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            PRESERVAÇÃO DA MASSA ÓSSEA COM O ENVELHECIMENTO
        </h2>
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            À medida que envelhecemos, a manutenção da massa óssea torna-se crucial para prevenir condições como a osteoporose. Pesquisas indicam que uma ingestão adequada de proteínas está associada a uma melhor densidade mineral óssea em idosos, reduzindo o risco de fraturas.
        </p>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            DENSIDADE ÓSSEA E PREVENÇÃO DE FRATURAS
        </h2>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            VARIAÇÃO NA INGESTÃO DE PROTEÍNAS E DENSIDADE MINERAL ÓSSEA (DMO)
        </h2>
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            As principais recomendações sobre ingestão adequada de proteína abordam a faixa "normal" de 0,8 a 1,2 g/kg de peso corporal por dia, mostrando impactos significativos na Densidade Mineral Óssea (DMO) em adultos.
        </p>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            PROTEÍNAS E PREVENÇÃO DE FRATURAS
        </h2>
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            Em idosos com osteoporose, uma ingestão mais elevada de proteínas (≥ 0,8 g/kg de peso corporal/dia) está associada a uma DMO mais alta, menor perda óssea e menor risco de fratura de quadril, contanto que exista uma ingestão dietética de cálcio adequada.
        </p>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            SUPLEMENTAÇÃO PROTEICA E MARCADORES DE REMODELAÇÃO ÓSSEA
        </h2>
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            Intervenções com suplementos proteicos demonstraram atenuar a diminuição relacionada à idade na DMO, reduzir marcadores de remodelação óssea e promover um ambiente mais favorável para a saúde óssea.
        </p><br />
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            Em resumo, a proteína desempenha um papel significativo na saúde óssea, desde o desenvolvimento até a idade avançada. Garantir uma ingestão adequada de proteínas, especialmente durante fases críticas do crescimento e ao envelhecer, é vital para promover ossos fortes e prevenir condições relacionadas à saúde óssea.
        </p>
    </>


}

export const MagnesioSaudeOssea = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    MAGNÉSIO</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            <strong>Absorção de cálcio</strong> - O magnésio ajuda o corpo a absorver o cálcio, que é o principal componente dos ossos.
            <br /><br />
            <strong>Estrutura óssea</strong> - O magnésio está envolvido na formação e manutenção da estrutura óssea. Ele contribui para a resistência e durabilidade dos ossos.
            <br /><br />
            <strong>Previne fragilidade</strong> - Ter magnésio suficiente pode ajudar a prevenir a fragilidade dos ossos, tornando-os menos propensos a quebrar.
            <br /><br />
            <strong>Saúde geral dos ossos</strong> - Além de ajudar na absorção de cálcio, o magnésio desempenha um papel importante em várias reações que mantêm os ossos saudáveis e funcionando corretamente.  </p>
    </>


}


export const CalcioSaudeOssea = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    CÁLCIO</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            O cálcio é um mineral FUNDAMENTAL para a saúde óssea.
            <br /><br />
            <strong>Material de construção</strong> - Os ossos são como a estrutura de uma casa e o cálcio é o material principal para construir e manter essa estrutura forte.
            <br /><br />
            <strong>Prevenção de fragilidade</strong> - Quando você tem cálcio suficiente, seus ossos ficam mais fortes e “duros” e menos propensos a fraturas.
            <br /><br />
            <strong>Prevenção de doenças ósseas</strong> - Ter cálcio suficiente ao longo da vida ajuda a prevenir problemas nos ossos, como a osteoporose, que torna os ossos frágeis e mais propensos a fraturas.</p>
    </>


}

export const VitaminaDSaudeOssea = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    VITAMINA D</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>

            A vitamina D possui um papel crucial para manter os ossos fortes e saudáveis.
            <br /><br />
            <strong>Absorção de cálcio</strong> - A vitamina D ajuda o corpo a absorver o cálcio dos alimentos. E o cálcio é um mineral essencial para construir e manter a força dos ossos.
            <br /><br />
            <strong>Minimiza a perda óssea</strong> - A vitamina D também ajuda a reduzir a perda de cálcio pelos ossos. Isso é importante porque, ao longo do tempo, a perda excessiva de cálcio pode enfraquecer os ossos, tornando-os mais propensos a fraturas.
            <br /><br />
            <strong>Previne doenças ósseas</strong> - A falta de vitamina D pode levar a problemas nos ossos, como o raquitismo em crianças e a osteoporose em adultos.
            <br /><br />
            <strong>Desenvolvimento ósseo</strong> - Durante a infância e adolescência, a vitamina D é ainda mais crucial, pois ajuda no desenvolvimento ósseo adequado. Crianças que não recebem vitamina D suficiente podem ter problemas no crescimento e desenvolvimento dos ossos.

        </p>
    </>


}