import React from 'react';
export const ProteinaSaudeMuscular = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    PROTEÍNA</h2>


        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            A PROTEÍNA É O ALICERCE DOS MÚSCULOS.
        </h2>
        <h2 className='colorWhite   midTitle5 full-width  text-left' style={{ marginTop: '15px' }}>
            SEJA PARA GANHO OU PARA A MANUTENÇÃO MUSCULAR!
        </h2><br />
        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            Ingerir quantidades adequadas de proteína auxilia na MANUTENÇÃO DA MASSA MUSCULAR e promove o CRESCIMENTO MUSCULAR durante o treinamento de força (como, por exemplo, a musculação). Inúmeros estudos apontam para o benefício da adequada ingestão de proteína para AUMENTO DE MASSA E A FORÇA MUSCULAR.
            <br /><br />
            Para praticantes de atividade física, incluindo a musculação, é preciso ainda maior atenção para ingerir quantidades de proteínas suficiente às suas necessidades e PREVENIR O CATABOLISMO MUSCULAR. MANTER ALTA A INGESTÃO PROTEICA também pode ajudar a PREVENIR A PERDA MUSCULAR para àqueles que buscam e estão em algum PROCESSO PARA A PERDA DE PESO.
            <br /><br />
            Em resumo, ao longo da última década, os potenciais benefícios relacionados com os músculos alcançados pelo consumo de dietas ricas em proteínas tornaram-se cada vez mais claros. Como por exemplo:
            <br /><br />
            maiores ganhos de força e massa muscular quando associado ao exercício resistido;<br /><br />
            maior preservação da massa muscular quando consumido durante período com baixo consumo de calorias;<br /><br />
            limita a perda muscular relacionada à idade;
            <br /><br />
            estudos também demonstram associações inversas entre a ingestão de proteínas e circunferência da cintura, peso corporal e índice de massa corporal (IMC).
        </p>

    </>


}

export const MagnesioSaudeMuscular = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    MAGNÉSIO</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            Contração muscular - O magnésio ajuda a enviar mensagens aos seus músculos, dizendo quando eles devem contrair e relaxar. É como o maestro de uma orquestra, garantindo que todos os músculos trabalhem em harmonia.
            <br /><br />
            Equilíbrio na atividade muscular - O magnésio trabalha junto com outros "ajudantes", como cálcio e potássio, para garantir que a atividade muscular seja bem coordenada. Isso é crucial para que os músculos funcionem corretamente.
            <br /><br />
            Prevenção de cãibras e fadiga - Sem magnésio suficiente, os músculos podem ficar cansados mais facilmente e facilitar as cãibras. Ter magnésio suficiente ajuda a prevenir esses desconfortos.
            <br /><br />
            Recuperação pós-exercício - Depois de se exercitar, o magnésio também desempenha um papel importante na recuperação muscular, ajudando na reparação dos tecidos.

        </p>
    </>


}



export const VitaminaDSaudeMuscular = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    VITAMINA D</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>

            Contração muscular - A vitamina D também desempenha um papel na contração muscular. Quando os músculos se movem, a vitamina D está lá, ajudando a coordenar esse processo para que tudo funcione corretamente.
            <br /><br />
            Prevenção de fraqueza - A falta de vitamina D pode levar a músculos mais fracos e aumentar o risco de quedas, especialmente em pessoas mais velhas.
            <br /><br />
            Bom funcionamento geral - Além disso, a vitamina D desempenha um papel em muitas funções do corpo que afetam indiretamente os músculos, como a função do sistema nervoso.
        </p>
    </>


}

export const CalcioSaudeMuscular = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    CÁLCIO</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            O cálcio desempenha um papel fundamental em ajudar os músculos a contrair e relaxar, o que é essencial para quase todos os movimentos.
            <br /><br />
            Quando pensamos em levantar algo, como um copo, os nervos enviam sinais aos músculos para que se contraiam. O cálcio entra em cena, agindo como um sinalizador, fazendo o movimento acontecer.
            <br /><br />
            Depois que o movimento é feito, o cálcio ajuda os músculos a relaxar. É como se fosse um botão de desligar, permitindo que os músculos voltem ao seu estado normal.
            <br /><br />
            Por isso, manter uma dieta equilibrada com alimentos ricos em cálcio é uma maneira de garantir que seus músculos continuem em equilíbrio.
        </p> </>

}

export const VitaminaB12SaudeMuscular = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>   VITAMINA B12</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>

            Formação de músculos - A B12 desempenha um papel na síntese de proteínas, que é fundamental para a formação e reparação dos músculos. Isso significa que ela ajuda a construir e manter músculos saudáveis.
            <br /><br />
            Função nervosa: Além disso, a vitamina B12 é importante para o sistema nervoso, que se comunica com os músculos. Ter um sistema nervoso saudável é crucial para a coordenação e movimento muscular adequados.
            <br /><br />
            A falta de vitamina B12 pode levar à fraqueza muscular, fadiga e até problemas de coordenação. Ou seja, sem essa vitamina os músculos podem não funcionar tão bem quanto poderiam!
        </p>
    </>


}