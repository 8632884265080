import React, { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { scrollToID } from '../../../../utils/scrollTo';
const MenuMain = () => {
    const [active, setActive] = useState(false);

    const goTo = (ID) => {
        scrollToID(ID);
        setActive(false)
        document.getElementById(ID)?.focus();


    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Verifica se a tecla pressionada é Esc
            if (event.key === 'Escape') {
                setActive(false)

            }
        };

        // Adiciona o ouvinte de eventos ao document
        document.addEventListener('keydown', handleKeyDown);

        // Função de limpeza que remove o ouvinte de eventos quando o componente é desmontado
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return <>

        {!active ? <div onKeyDown={(event) => {
            if (event.key === 'Enter') {
                setActive(true);
            }
        }} tabIndex={0} onClick={() => setActive(true)} className='cursor fadeIn' style={
            {
                background: "#000",
                position: 'fixed',
                top: '15px',
                right: '15px',
                zIndex: 10000,
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'

            }
        }>
            <FiMenu className='colorWhite' style={{ fontSize: '35px' }} />
        </div> : <></>}

        {active ?

            <div className='fadeIn column pad gap-sm'
                style={{
                    background: "rgba(0,0,0,0.8)",
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    width: '100%',
                    maxWidth: '400px',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    zIndex: 10000,
                    overflowY: 'auto',
                    padding: '50px 30px'

                }}

            >

                <p style={{ border: '1px solid #fff', cursor: 'pointer', padding: '5px', borderRadius: '5px', margin: '10px 0px' }} tabIndex={0} onClick={() => setActive(false)} onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setActive(false);
                    }
                }}>Fechar</p>

                <nav tabIndex={0} onClick={() => goTo('home')} onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        goTo('home')
                    }
                }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    HOME
                </nav>
                <nav tabIndex={0} onClick={() => goTo('video-lancamento')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('video-lancamento')
                        }
                    }}

                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    VÍDEO DE LANÇAMENTO
                </nav>
                <nav tabIndex={0} onClick={() => goTo('ganho-muscular')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('ganho-muscular')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    GANHO MUSCULAR
                </nav>
                <nav tabIndex={0} onClick={() => goTo('energia')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('energia')
                        }
                    }}

                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    ENERGIA
                </nav>
                <nav tabIndex={0} onClick={() => goTo('vitalidade')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('vitalidade')
                        }
                    }}

                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    VITALIDADE
                </nav>
                <nav tabIndex={0} onClick={() => goTo('mix-nutricional')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('mix-nutricional')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    MIX DE NUTRIENTES
                </nav>
                <nav tabIndex={0} onClick={() => goTo('saude-ossea')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('saude-ossea')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    MIX: SAÚDE ÓSSEA
                </nav>
                <nav tabIndex={0} onClick={() => goTo('saude-muscular')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('saude-muscular')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    MIX: SAÚDE MUSCULAR
                </nav>
                <nav tabIndex={0} onClick={() => goTo('saude-energia')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('saude-energia')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    MIX: ENERGIA
                </nav>
                <nav tabIndex={0} onClick={() => goTo('rendimento')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('rendimento')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    RENDIMENTO
                </nav>
                <nav tabIndex={0} onClick={() => goTo('informacoes-nutricionais')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('informacoes-nutricionais')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    INFORMAÇÕES NUTRICIONAIS
                </nav>
                <nav tabIndex={0} onClick={() => goTo('rotina')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('rotina')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    COMO INSERIR NA SUA ROTINA
                </nav>
                <nav tabIndex={0} onClick={() => goTo('cafe-da-manha')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('cafe-da-manha')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    CAFÉ DA MANHÃ
                </nav>
                <nav tabIndex={0} onClick={() => goTo('lanches-intermediarios')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('lanches-intermediarios')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    LANCHES INTERMEDIÁRIOS
                </nav>
                <nav tabIndex={0} onClick={() => goTo('almoco')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('almoco')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    ALMOÇO
                </nav>
                <nav tabIndex={0} onClick={() => goTo('jantar')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('jantar')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    JANTAR
                </nav>
                <nav tabIndex={0} onClick={() => goTo('receitas')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('receitas')
                        }
                    }}
                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    RECEITAS
                </nav>
                <nav tabIndex={0} onClick={() => goTo('sobre-nos')}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            goTo('sobre-nos')
                        }
                    }}

                    className='full-width blackBackground pad-sm cursor midTitle4 scaleOnHover'>
                    SOBRE NÓS
                </nav>
            </div>

            : <></>}

    </>



}

export default MenuMain;