import React from 'react';
import { useModal } from '../../../../hooks/Modal';

const AlmocoEJantar = () => {
    const { addModal } = useModal();

    return [

        <div className='column gap-sm max-400 cursor' tabIndex={0}

            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    addModal({
                        title: '', theme: 'blackModalMedium ', content: <>

                            <div className='column max-400'>


                                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                                    <strong className='color1' >ESCONDIDINHO</strong>
                                </p>


                                <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>
                                    Que escondidinho é uma delícia todo mundo sabe né. Agora com teor de proteína aumentado vai ficar ainda melhor. Para isso, basta adicionar o novo leite em pó CCGL, já diluído, à sua massa clássica e aproveitar. </p></div>
                            <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                    <strong className='color2'>VERSATILIDADE</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                    <strong className='color2' >VARIEDADE</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                    <strong className='color2' >COMFORT FOOD</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                    <strong className='color2' >+ PROTEÍNAS</strong>
                                </p>
                            </div>


                        </>
                    })
                }
            }}


            onClick={() => {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                                <strong className='color1' >ESCONDIDINHO</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>
                                Que escondidinho é uma delícia todo mundo sabe né. Agora com teor de proteína aumentado vai ficar ainda melhor. Para isso, basta adicionar o novo leite em pó CCGL, já diluído, à sua massa clássica e aproveitar. </p></div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>VERSATILIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >VARIEDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >COMFORT FOOD</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })


            }}

        >

            <div className='column max-400  scaleOnHover'>

                <img alt='Bowl branco com um garfo retirando pedaço de escondidinho de carne em primeiro plano. Em segundo plano mais um bowl de arroz branco e outro com pimentas de cheiro. ' src="/apoio/v3/escondidinho.jpeg" />
                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                    <strong className='color1' >ESCONDIDINHO</strong>
                </p>


            </div>

        </div>,


        <div className='column gap-sm max-400 cursor' tabIndex={0}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    addModal({
                        title: '', theme: 'blackModalMedium ', content: <>
                            <div className='column max-400'>


                                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                    <strong className='color1' >PANQUECA PROTEICA + ACOMPANHAMENTOS DE PREFERÊNCIA</strong>
                                </p>


                                <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                    As panquecas são famosas pela sua versatilidade, podendo ser base para um alimento doce ou salgado!
                                    <br /><br />
                                    E que tal adicionar proteína a essa massa? Para isso, basta substituir o leite fluído da panqueca por leite em pó CCGL (já preparado), adicionar o resto dos ingredientes de sua preferência (farinha, óleo, sal, ovo) e pronto!
                                </p>
                            </div>
                            <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                    <strong className='color2'>PREPARAÇÃO RÁPIDA</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                    <strong className='color2' >VERSATILIDADE</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                    <strong className='color2' >+ PRATICIDADE</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                    <strong className='color2' >+ PROTEÍNAS</strong>
                                </p>
                            </div>


                        </>
                    })
                }
            }}
            onClick={() => {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>
                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >PANQUECA PROTEICA + ACOMPANHAMENTOS DE PREFERÊNCIA</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                As panquecas são famosas pela sua versatilidade, podendo ser base para um alimento doce ou salgado!
                                <br /><br />
                                E que tal adicionar proteína a essa massa? Para isso, basta substituir o leite fluído da panqueca por leite em pó CCGL (já preparado), adicionar o resto dos ingredientes de sua preferência (farinha, óleo, sal, ovo) e pronto!
                            </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>PREPARAÇÃO RÁPIDA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >VERSATILIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ PRATICIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })


            }}

        >

            <div className='column max-400  scaleOnHover'>

                <img alt='Prato sobre a mesa de madeira com várias panquecas estilo americanas decoradas com bananas e leite em pó.' src="/apoio/v3/panqueca-proteico.jpeg" />
                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                    <strong className='color1' >PANQUECA PROTEICA + ACOMPANHAMENTOS DE PREFERÊNCIA</strong>
                </p>


            </div>

        </div>,
        <div className='column gap-sm max-400 cursor' tabIndex={0}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    addModal({
                        title: '', theme: 'blackModalMedium ', content: <>

                            <div className='column max-400'>


                                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                    <strong className='color1' >CREMES PROTEÍCOS</strong>
                                </p>


                                <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                    A combinação de arroz e feijão está presente na maioria das ocasiões de consumo dos brasileiros nessa refeição. E que tal um acompanhamento para esse clássico?
                                    <br /><br />
                                    Esses acompanhamentos são fonte de carboidratos, mas podem ter uma dose extra de proteína! Para isso, basta adicionar o novo leite em pó CCGL as suas receitas de purês e cremes, como os clássicos purê de batata ou creme de milho.

                                </p>
                            </div>

                            <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                                <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                    <strong className='color2'>CONFORT FOOD</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                    <strong className='color2' >VERSATILIDADE</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                    <strong className='color2' >LOW CARB</strong>
                                </p>
                                <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                                    <strong className='color2' >+ PROTEÍNAS</strong>
                                </p>
                            </div>


                        </>
                    })
                }
            }}
            onClick={() => {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >CREMES PROTEÍCOS</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                A combinação de arroz e feijão está presente na maioria das ocasiões de consumo dos brasileiros nessa refeição. E que tal um acompanhamento para esse clássico?
                                <br /><br />
                                Esses acompanhamentos são fonte de carboidratos, mas podem ter uma dose extra de proteína! Para isso, basta adicionar o novo leite em pó CCGL as suas receitas de purês e cremes, como os clássicos purê de batata ou creme de milho.

                            </p>
                        </div>

                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2'>CONFORT FOOD</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >VERSATILIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >LOW CARB</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })


            }}

        >

            <div className='column max-400  scaleOnHover'>

                <img alt='Bowl branco com creme de milho decorado com alguns grãos de milho e salsinha. Mesa decorada com espigas de milho e fatias de pão.' src="/apoio/v3/creme-proteico.jpeg" />
                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                    <strong className='color1' >CREMES PROTEÍCOS</strong>
                </p>


            </div>

        </div>

    ]



}


export default AlmocoEJantar;