
import React, { useEffect, useState } from 'react';


const InformacoesNutrucionais: React.FC = ({


}) => {

    const data = [
        { class_name: 'background3', title: 'Valor energético (kcal)', '100': '50', '30': '100', vd: '5' },
        { class_name: 'background3', title: 'Carboidratos (g)', '100': '6,5', '30': '13', vd: '4' },
        { class_name: 'background3', title: 'Açúcares totais (g)', '100': '6,5', '30': '13', vd: '' },
        { class_name: '', title: 'Açúcares adicionados (g)', '100': '0', '30': '0', vd: '0' },
        { class_name: '', title: 'Proteínas (g)', '100': '6', '30': '12', vd: '24' },
        { class_name: '', title: 'Gorduras totais (g)', '100': '0', '30': '0', vd: '0' },
        { class_name: '', title: 'Gorduras saturadas (g)', '100': '0', '30': '0', vd: '0' },
        { class_name: '', title: 'Gorduras trans (g)', '100': '0', '30': '0', vd: '0' },
        { class_name: '', title: 'Fibras alimentares (g)', '100': '0', '30': '0', vd: '0' },
        { class_name: '', title: 'Sódio (mg)', '100': '59', '30': '118', vd: '6' },
        { class_name: 'background3', title: 'Vitamina D', '100': '2,3', '30': '4,5', vd: '30' },
        { class_name: 'background3', title: 'Vitamina C', '100': '15', '30': '30', vd: '30' },
        { class_name: 'background3', title: 'Vitamina B!', '100': '0,09', '30': '0,18', vd: '15' },
        { class_name: 'background3', title: 'Vitamina B3', '100': '1,1', '30': '2,3', vd: '15' },
        { class_name: 'background3', title: 'Vitamina B5', '100': '0,38', '30': '0,75', vd: '15' },
        { class_name: 'background3', title: 'Vitamina B12', '100': '0,18', '30': '0,36', vd: '15' },
        { class_name: 'background3', title: 'Cálcio (mg)', '100': '249', '30': '498', vd: '50' },
        { class_name: 'background3', title: 'Magnésio (mg)', '100': '32', '30': '63', vd: '15' }

    ]



    return <div className=' '>

        <h2 className='colorWhite' style={{ fontSize: '28px' }}>Informações nutricionais</h2>
        <p>Porções por embalagem: 9 | Porção: 30g (3 colheres de sopa)</p>
        <br />
        <div className='background2 pad-sm'>
            <table className='nutritionalTable background2 color1 max-600 pad'>
                <tbody>
                    <tr><th></th>       	<th>100 ml**</th>	<th>30g	</th><th>%VD(*)</th></tr>
                    {data?.map(item => <tr className={item?.class_name}>
                        <td>{item?.title}</td>
                        <td>{item?.['100']}</td>
                        <td>{item?.['30']}</td>
                        <td>{item?.['vd']}</td>
                    </tr>)}

                </tbody>
            </table>


        </div>
        <br />
        <p style={{ color: '#fff', fontSize: '10px', width: '100%', maxWidth: '600px' }}>
            INGREDIENTES: Leite pasteurizado desnatado, concentrado proteico de leite em pó, mistura de vitaminas e minerais: sulfato de megnésio monoidratado (magnésio), carbonato de cálcio (cálcio), ácido ascórbico (vitamina C), nicotinamida (vitamina B3), colecalciferol (vitamina D), D-pantotenato de cálcio (vitamina B5), cianocobalamina (vitamina B12) e tiamina mononitrato (vitamina B1). ALÉRGICOS: CONTÉM LEITE. CONTÉM LACTOSE. NÃO CONTÉM GLÚTEN.
        </p>
    </div>


}

export default InformacoesNutrucionais;