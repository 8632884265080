import React from 'react';


export const MagnesioEnergia = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    MAGNÉSIO</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            O magnésio desempenha um papel fundamental em várias vias metabólicas relacionadas à produção e utilização de energia no corpo.
            <br /><br />
            Fábrica de energia (ATP) - O magnésio é essencial na síntese e na estabilidade do ATP, que é a principal moeda de troca de energia para as células.
            <br /><br />
            Metabolismo energético - O magnésio participa em várias reações , incluindo no corpo, que ajudam a “quebrar” o alimento e transformá-lo em energia. Como a glicólise (quebra de glicose) e o ciclo de Krebs (produção de energia nas mitocôndrias).
            <br /><br />
            Transporte de Íons - O magnésio ajuda a equilibrar os íons, especialmente o cálcio, dentro das células. Esse equilíbrio é como manter as coisas organizadas para que as células possam fazer seu trabalho e produzir energia de maneira eficaz.
            <br /><br />
            Estabilidade das células - O magnésio ajuda a manter as células estáveis, garantindo que elas estejam saudáveis e prontas para produzir e usar energia quando necessário.
        </p>
    </>


}


export const VitaminaB1Energia = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    VITAMINA B1</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>
            Transforma comida em energia - A vitamina B1 ajuda o seu corpo a transformar os alimentos que você come, especialmente carboidratos em energia.
            <br /><br />
            Produção de ATP - A vitamina B1 desempenha um papel importante na produção de uma molécula chamada ATP (Adenosina Trifosfato), que é que é a principal moeda de troca de energia para as células.
        </p> </>

}

export const VitaminaB5Energia = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    VITAMINA B5</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>

            Transformação de Alimentos em Energia - A vitamina B5 desempenha um papel crucial na quebra dos alimentos que você come, especialmente gorduras e carboidratos, para transformá-los em energia.
            <br /><br />
            Produção de Coenzima A - A vitamina B5 é uma parte essencial da coenzima A, uma molécula que desempenha um papel central na produção de uma forma de energia chamada ATP (Adenosina Trifosfato).
            <br /><br />
            Metabolismo de Nutrientes - Além de ajudar na produção de energia, a vitamina B5 está envolvida no metabolismo de outros nutrientes, como proteínas. Isso é importante porque todas essas peças se encaixam para fornecer ao seu corpo o combustível necessário.  </p>
    </>


}

export const VitaminaB3Energia = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    VITAMINA B3</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>

            Metabolismo de nutrientes - A vitamina B3 está envolvida no metabolismo de carboidratos, gorduras e proteínas. Ela ajuda a quebrar esses nutrientes para que possam ser usados como fonte de energia pelo seu corpo.
            <br /><br />
            Produção de ATP - A niacina contribui para a produção de uma molécula chamada ATP (Adenosina Trifosfato), que é como a bateria que fornece energia para as células realizarem suas funções diárias.
            <br /><br />
            Função das enzimas - A niacina atua como cofator para várias enzimas no corpo. As enzimas são como trabalhadores que aceleram as reações químicas necessárias para liberar energia.

        </p>
    </>


}

export const VitaminaCEnergia = () => {

    return <>
        <h2 className='colorWhite   midTitle2 color2 full-width  text-left' style={{ marginTop: '15px' }}>    VITAMINA C</h2>

        <p className='contentParagraphSimple text-left colorWhite' style={{ textAlign: 'left' }}>

            Metabolismo de energia - A vitamina C participa do metabolismo de carboidratos, ajudando a converter os alimentos em energia utilizável pelo corpo. Ela atua como um cofator em algumas reações químicas envolvidas no processo metabólico.
            <br /><br />
            Absorção de ferro - A vitamina C melhora a absorção de ferro não-heme, a forma de ferro encontrada em alimentos de origem vegetal. O ferro é essencial para o transporte de oxigênio no sangue e, assim, contribui para a produção de energia no corpo.
            <br /><br />
            Embora a vitamina C não forneça energia diretamente, seu papel em processos metabólicos e na promoção da saúde celular contribui para a vitalidade e o bem-estar geral, ajudando indiretamente na sensação de energia.

        </p>
    </>


}