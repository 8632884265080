import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,

} from './styles';



import { useAuth } from '../../hooks/Auth';

import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';

import api from '../../services/api';
import { hash, urlWebsite } from '../../config';

import { loadFromJson } from '../../utils/loadFromJson';
import { relative } from 'path';
import Slider from 'react-slick';

import LeiteEmPo from '../ComoEFeito/modules/LeiteEmPo';
import MixDeNutrientes from './modules/MixDeNutrientes';
import Proteina from './modules/Proteina';
import SaudeMuscular from './modules/SaudeMuscular';
import Energia from './modules/Energia';




interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Nutrientes: React.FC = () => {

  const { setMenu } = usePages();
  const { setAvailableLanguages } = useLanguage();
  const { translate } = useLanguage();
  const lorem = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
  const { addModal, removeModal } = useModal();
  const [slides, setSlides] = useState<Array<JSX.Element>>([]);
  const { user } = useAuth();





  return (<>
    <img src="/apoio/cabecalho-leite.png" style={{ width: '100%', zIndex: 10000 }} />



    <Container className=' column fadeIn' style={{ padding: '100px 50px', background: 'none', zIndex: 900 }}>
      <h2 className='hugeTitle2  colorWhite'>
        MIX DE NUTRIENTES
      </h2>

      <LeiteEmPo />
      <br />  <br />
      <div className='row-to-column gap'>

        <p className="midTitle2 pad " style={{ color: '#fff', width: '100%', maxWidth: '450px' }}>
          Para isso, fizemos
          um estudo científico aprofundado para entender quais nutrientes poderiam fornecer benefícios funcionais e nutricionais para a <strong className='colorWhite'>SAÚDE ÓSSEA</strong>, <strong className='colorWhite'>SAÚDE MUSCULAR</strong> e <strong className='colorWhite'>ENERGIA</strong>.
          <br /><br />
          Com isso, chegamos
          a um <strong className='colorWhite'>MIX DE NUTRIENTES</strong> </p>


        <MixDeNutrientes />

      </div>

      <br /><br />

    </Container>

    <Container className='fadeIn'>

      <h2 className='hugeTitle2  colorWhite center'>
        VAMOS ENTENDER MELHOR <br />O PAPEL DESSES NUTRIENTES <br />PARA A NOSSA SAÚDE?
      </h2>

      <br />

      <div className='row gap'>
        <img src="/apoio/pro/osso.png" style={{ width: '70px' }} />
        <h2 className='hugeTitle2  color2 center'>SAÚDE ÓSSEA</h2>
      </div>

      <div className='separadorLp' />
      <p className='contentParagraphSimple text-left max-1000'>
        <br />
        <h2 className=' midTitle2  color2    '>PROTEÍNA</h2>
        <br />
        <p className='colorWhite' >
          <strong>DESENVOLVIMENTO ÓSSEO DURANTE O CRESCIMENTO</strong><br />
          Durante o crescimento, especialmente na infância e adolescência, a ingestão adequada de proteínas é essencial para o desenvolvimento ósseo ideal. Estudos mostram que a proteína fornece os aminoácidos necessários para a formação e o crescimento do tecido ósseo, ajudando a atingir a densidade mineral óssea desejada.
          <br /><br />
          <strong>PRESERVAÇÃO DA MASSA ÓSSEA COM O ENVELHECIMENTO</strong><br />
          À medida que envelhecemos, a manutenção da massa óssea torna-se crucial para prevenir condições como a osteoporose. Pesquisas indicam que uma ingestão adequada de proteínas está associada a uma melhor densidade mineral óssea em idosos, reduzindo o risco de fraturas.

          <br /><br />
          <strong>DENSIDADE ÓSSEA E PREVENÇÃO DE FRATURAS</strong>
          <br /><br />

          <strong>VARIAÇÃO NA INGESTÃO DE PROTEÍNAS E DENSIDADE MINERAL ÓSSEA (DMO)</strong><br />
          As principais recomendações sobre ingestão adequada de proteína abordam a faixa "normal" de 0,8 a 1,2 g/kg de peso corporal por dia, mostrando impactos significativos na Densidade Mineral Óssea (DMO) em adultos.
          <br /><br />

          <strong>PROTEÍNAS E PREVENÇÃO DE FRATURAS</strong><br />
          Em idosos com osteoporose, uma ingestão mais elevada de proteínas (≥ 0,8 g/kg de peso corporal/dia) está associada a uma DMO mais alta, menor perda óssea e menor risco de fratura de quadril, contanto que exista uma ingestão dietética de cálcio adequada.
          <br /><br />

          <strong>SUPLEMENTAÇÃO PROTEICA E MARCADORES DE REMODELAÇÃO ÓSSEA</strong><br />
          Intervenções com suplementos proteicos demonstraram atenuar a diminuição relacionada à idade na DMO, reduzir marcadores de remodelação óssea e promover um ambiente mais favorável para a saúde óssea.
          <br /><br />

          Em resumo, a proteína desempenha um papel significativo na saúde óssea, desde o desenvolvimento até a idade avançada. Garantir uma ingestão adequada de proteínas, especialmente durante fases críticas do crescimento e ao envelhecer, é vital para promover ossos fortes e prevenir condições relacionadas à saúde óssea.

        </p></p>


    </Container>

    <Container className='background2'>


      <h2 className=' midTitle2  color4    '>PROTEÍNA</h2>
      <h2 className=' midTitle2  color1    '> VITAMINA D | CÁLCIO | MAGNÉSIO</h2>
      <br />
      <div className='full-width max-2000 gap row-to-column'>

        <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
          {Proteina()}
        </div>
        <div className='full-width  mobileOnly'>
          <Slider autoplay
            responsive={[

              {
                breakpoint: 1200,
                settings: {

                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
            {Proteina()}
          </Slider>
        </div>
      </div>
    </Container>

    <Container className='background4'>


      <br />

      <div className='row gap'>
        <img src="/apoio/pro/musculo.png" style={{ width: '70px' }} />
        <h2 className='hugeTitle2  color2 center'>SAÚDE MUSCULAR</h2>
      </div>

      <div className='separadorLp' />
      <p className='contentParagraphSimple text-left max-1000'>
        <br />
        <h2 className=' midTitle2  color2    '>PROTEÍNA</h2>
        <br />
        <p className='colorWhite' >
          <strong> A PROTEÍNA É O ALICERCE DOS MÚSCULOS. </strong><br />
          <strong> SEJA PARA GANHO OU PARA A MANUTENÇÃO MUSCULAR!</strong>
          <br />
          <br />
          Ingerir quantidades adequadas de proteína auxilia na  <strong>MANUTENÇÃO DA MASSA MUSCULAR</strong> e promove o <strong>CRESCIMENTO MUSCULAR</strong> durante o treinamento de força (como, por exemplo, a musculação). Inúmeros estudos apontam para o benefício da adequada ingestão de proteína para <strong>AUMENTO DE MASSA E A FORÇA MUSCULAR</strong>.
          <br />
          <br />
          Para praticantes de atividade física, incluindo a musculação, é preciso ainda maior atenção para ingerir quantidades de proteínas suficiente às suas necessidades e <strong>PREVENIR O CATABOLISMO MUSCULAR. MANTER ALTA A INGESTÃO PROTEICA</strong> também pode ajudar a <strong>PREVENIR A PERDA MUSCULAR</strong> para àqueles que buscam e estão em algum <strong>PROCESSO PARA A PERDA DE PESO</strong>.
          <br />
          <br />
          Em resumo, ao longo da última década, os potenciais benefícios relacionados com os músculos alcançados pelo consumo de dietas ricas em proteínas tornaram-se cada vez mais claros. Como por exemplo:
          <br />
          <br />
          maiores ganhos de força e massa muscular quando associado ao exercício resistido;
          <br />
          <br />
          maior preservação da massa muscular quando consumido durante período com baixo consumo de calorias;
          <br />
          <br />
          limita a perda muscular relacionada à idade;
          <br />
          <br />
          estudos também demonstram associações inversas entre a ingestão de proteínas e circunferência da cintura, peso corporal e índice de massa corporal (IMC).
        </p></p>


    </Container>

    <Container className='background4'>
      <br />    <br />
      <h2 className=' midTitle2  color2    '> MAGNÉSIO | CÁLCIO | VITAMINA D | VITAMINA B12 </h2>
      <br />    <br />
      <div className='full-width max-2000 gap row-to-column'>

        <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
          {SaudeMuscular()}
        </div>
        <div className='full-width  mobileOnly'>
          <Slider autoplay
            responsive={[

              {
                breakpoint: 1200,
                settings: {

                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
            {SaudeMuscular()}
          </Slider>
        </div>
      </div>
    </Container>

    <Container >
      <div className='row gap'>
        <img src="/apoio/pro/energia.png" style={{ width: '70px' }} />
        <h2 className='hugeTitle2  color2 center'>ENERGIA</h2>
      </div>
      <br />    <br />
      <h2 className=' midTitle2  color2    '> MAGNÉSIO | CÁLCIO | VIT B1 | VIT B5 | VIT B3 | VIT C </h2>
      <br />    <br />
      <div className='full-width max-2000 gap row-to-column'>

        <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
          {Energia()}
        </div>
        <div className='full-width  mobileOnly'>
          <Slider autoplay
            responsive={[

              {
                breakpoint: 1200,
                settings: {

                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
            {Energia()}
          </Slider>
        </div>
      </div>
    </Container>
    <img src="/apoio/rodape-leite.png" style={{ width: '100%' }} />

  </>
  );
};
export default Nutrientes;
