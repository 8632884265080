import React from 'react';

const SaudeMuscular = () => {


    return [<div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200 pad-xs background1 displayReceitasTitle'>
                <strong className='colorWhite background1' >MAGNÉSIO</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '16px', background: '#fff', alignItems: 'stretch' }}>
                Contração muscular - O magnésio ajuda a enviar mensagens aos seus músculos, dizendo quando eles devem contrair e relaxar. É como o maestro de uma orquestra, garantindo que todos os músculos trabalhem em harmonia.
                <br /><br />
                Equilíbrio na atividade muscular - O magnésio trabalha junto com outros "ajudantes", como cálcio e potássio, para garantir que a atividade muscular seja bem coordenada. Isso é crucial para que os músculos funcionem corretamente.
                <br /><br />
                Prevenção de cãibras e fadiga - Sem magnésio suficiente, os músculos podem ficar cansados mais facilmente e facilitar as cãibras. Ter magnésio suficiente ajuda a prevenir esses desconfortos.
                <br /><br />
                Recuperação pós-exercício - Depois de se exercitar, o magnésio também desempenha um papel importante na recuperação muscular, ajudando na reparação dos tecidos.  </p>
        </div>

    </div>,

    <div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200  pad-xs background1 displayReceitasTitle'>
                <strong className='colorWhite background1' >CÁLCIO</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '14px', background: '#fff' }}>
                O cálcio desempenha um papel fundamental em ajudar os músculos a contrair e relaxar, o que é essencial para quase todos os movimentos.
                <br /><br />
                Quando pensamos em levantar algo, como um copo, os nervos enviam sinais aos músculos para que se contraiam. O cálcio entra em cena, agindo como um sinalizador, fazendo o movimento acontecer.
                <br /><br />
                Depois que o movimento é feito, o cálcio ajuda os músculos a relaxar. É como se fosse um botão de desligar, permitindo que os músculos voltem ao seu estado normal.
                <br /><br />
                Por isso, manter uma dieta equilibrada com alimentos ricos em cálcio é uma maneira de garantir que seus músculos continuem em equilíbrio. </p>
        </div>

    </div>,

    <div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200  pad-xs background1  displayReceitasTitle'>
                <strong className='colorWhite background1' >VITAMINA D</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '14px', background: '#fff' }}>
                O cálcio é um mineral muito importante para saúde muscular.
                <br /><br />

                Contração muscular - A vitamina D também desempenha um papel na contração muscular. Quando os músculos se movem, a vitamina D está lá, ajudando a coordenar esse processo para que tudo funcione corretamente.
                <br /><br />
                Prevenção de fraqueza - A falta de vitamina D pode levar a músculos mais fracos e aumentar o risco de quedas, especialmente em pessoas mais velhas.
                <br /><br />
                Bom funcionamento geral - Além disso, a vitamina D desempenha um papel em muitas funções do corpo que afetam indiretamente os músculos, como a função do sistema nervoso.
            </p>
        </div>

    </div >
        ,

    <div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200  pad-xs background1  displayReceitasTitle'>
                <strong className='colorWhite background1' >VITAMINA B12</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '14px', background: '#fff' }}>
                Formação de músculos - A B12 desempenha um papel na síntese de proteínas, que é fundamental para a formação e reparação dos músculos. Isso significa que ela ajuda a construir e manter músculos saudáveis.
                <br /><br />
                Função nervosa: Além disso, a vitamina B12 é importante para o sistema nervoso, que se comunica com os músculos. Ter um sistema nervoso saudável é crucial para a coordenação e movimento muscular adequados.
                <br /><br />
                A falta de vitamina B12 pode levar à fraqueza muscular, fadiga e até problemas de coordenação. Ou seja, sem essa vitamina os músculos podem não funcionar tão bem quanto poderiam!
            </p>
        </div>
    </div>

    ]



}


export default SaudeMuscular;