import React from 'react';
import { useModal } from '../../../../hooks/Modal';

const LanchesIntermediarios = () => {
    const { addModal } = useModal();

    return [<div className='column gap-sm max-400 cursor' tabIndex={0}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >BITES PROTEICOS
                                    DE LEITE EM PÓ</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                No Brasil, tem crescido o consumo de snacks, ou seja, pequenos lanches com maior frequência ao longo do dia, principalmente pelas gerações mais jovens e trabalhadores home office, que costumam fazer mais pausas do que no escritório.
                                <br />Então que tal um snack muito prático feito com leite em pó?
                                <br /><br />
                                Para preparar basta peneirar o leite em pó em uma tigela, ir colocando, aos poucos, leite fluído ou água até dar o ponto de enrolar. Depois, enrolar e passar leite em pó para cobrir todas as bolinhas. </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2'>PRATICIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >INDULGÊNCIA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >PÓS TREINO</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}
        onClick={() => {
            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                            <strong className='color1' >BITES PROTEICOS
                                DE LEITE EM PÓ</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                            No Brasil, tem crescido o consumo de snacks, ou seja, pequenos lanches com maior frequência ao longo do dia, principalmente pelas gerações mais jovens e trabalhadores home office, que costumam fazer mais pausas do que no escritório.
                            <br />Então que tal um snack muito prático feito com leite em pó?
                            <br /><br />
                            Para preparar basta peneirar o leite em pó em uma tigela, ir colocando, aos poucos, leite fluído ou água até dar o ponto de enrolar. Depois, enrolar e passar leite em pó para cobrir todas as bolinhas. </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2'>PRATICIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2' >INDULGÊNCIA</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2' >PÓS TREINO</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                            <strong className='color2' >+ PROTEÍNAS</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Bowl pequeno de louça cheio de bites proteicos polvilhados com leite em pó.' src="/apoio/tarde1.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                <strong className='color1' >BITES PROTEICOS
                    DE LEITE EM PÓ</strong>
            </p>

        </div>

    </div>,

    <div className='column gap-sm max-400 cursor' tabIndex={0}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                                <strong className='color1' >AÇAÍ PROTEICO</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>
                                De acordo com o EMBRAPA (2023) o consumo de açaí aumenta cerca de 15% a cada ano no Brasil, sendo um alimento muito popular e altamente nutritivo.
                                <br /><br />
                                Também é um excelente alimento para o pré e pós treino, devido a toda sua composição nutricional, e combina perfeitamente com o leite em pó, sendo essa uma união que já faz parte do hábito de consumo do brasileiro.

                                <br /><br />
                                Para esse lanche, basta intercalar camadas de açaí com o novo leite em pó proteico CCGL e pronto! É só se deliciar.
                            </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>+ PROTEÍNA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >PRÉ/ PÓS TREINO</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ VITAMINAS</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >ANTIOXIDANTES</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}
        onClick={() => {
            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                            <strong className='color1' >AÇAÍ PROTEICO</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>
                            De acordo com o EMBRAPA (2023) o consumo de açaí aumenta cerca de 15% a cada ano no Brasil, sendo um alimento muito popular e altamente nutritivo.
                            <br /><br />
                            Também é um excelente alimento para o pré e pós treino, devido a toda sua composição nutricional, e combina perfeitamente com o leite em pó, sendo essa uma união que já faz parte do hábito de consumo do brasileiro.

                            <br /><br />
                            Para esse lanche, basta intercalar camadas de açaí com o novo leite em pó proteico CCGL e pronto! É só se deliciar.
                        </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2'>+ PROTEÍNA</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >PRÉ/ PÓS TREINO</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ VITAMINAS</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                            <strong className='color2' >ANTIOXIDANTES</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Bowl de louça marrom cheio de assaí decorado com morangos e bananas e polvilhado com leite em pó. ' src="/apoio/v3/acai-proteico.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                <strong className='color1' >AÇAÍ PROTEICO</strong>
            </p>



        </div>

    </div>,


    <div className='column gap-sm max-400 cursor' tabIndex={0}

        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >PUDIM DE CHIA</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                Essa é uma receita popular para quem busca praticidade, sendo uma opção que pode ser usada no café da manhã, lanche da tarde ou até mesmo como sobremesa! Adicionando fibras e ômega 3 (chia), vitaminas (frutas), carboidratos e proteína (novo leite em pó CCGL) ao seu dia.
                                <br /><br />
                                Para preparar, basta misturar a chia com iogurte natural, ou leite vegetal, e deixar hidratando. Em outro recipiente, misturar o leite em pó com um pouco de água, aos poucos, até que adquira uma consistência de calda, coloque a calda de leite em pó em cima do pudim e adicione frutas picadas de sua preferência!

                            </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>+ PROTEÍNA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ OMEGA3</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ PRATICIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >+ FIBRAS</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}
        onClick={() => {
            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                            <strong className='color1' >PUDIM DE CHIA</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                            Essa é uma receita popular para quem busca praticidade, sendo uma opção que pode ser usada no café da manhã, lanche da tarde ou até mesmo como sobremesa! Adicionando fibras e ômega 3 (chia), vitaminas (frutas), carboidratos e proteína (novo leite em pó CCGL) ao seu dia.
                            <br /><br />
                            Para preparar, basta misturar a chia com iogurte natural, ou leite vegetal, e deixar hidratando. Em outro recipiente, misturar o leite em pó com um pouco de água, aos poucos, até que adquira uma consistência de calda, coloque a calda de leite em pó em cima do pudim e adicione frutas picadas de sua preferência!

                        </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2'>+ PROTEÍNA</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ OMEGA3</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ PRATICIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                            <strong className='color2' >+ FIBRAS</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400 scaleOnHover'>

            <img alt='Copo de vidro cheio de chia com leite, decorado com frutas vermelhas.' src="/apoio/tarde3.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                <strong className='color1' >PUDIM DE CHIA</strong>
            </p>


        </div>

    </div>]



}


export default LanchesIntermediarios;