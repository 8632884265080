
import React, { useEffect, useState } from 'react';
import LeiteEmPo from '../../../ComoEFeito/modules/LeiteEmPo';


const MixDeNutrientes: React.FC = ({


}) => {



    return <div className='pad-xs' style={{ background: '#fff' }}>


        <div className=' pad-sm'>
            <table className='nutritionalTableMix borderedTable color1 max-600 pad'>
                <tbody>
                    <tr className='background4 colorWhite'><th>SAÚDE ÓSSEA</th>       	<th>SAÚDE MUSCULAR</th>	<th>ENERGIA</th> </tr>
                    <tr><td className='background5 color4'>
                        <p className='color4 pad-xs'>Está relacionada à formação e manutenção
                            de ossos fortes, prevenindo fraturas.</p>
                    </td>
                        <td className='background5 color4'>
                            <p className='color4 pad-xs'>A ingestão alimentar adequada auxilia na formação, manutenção e contração muscular. </p>
                        </td>
                        <td className='background5 color4' rowSpan={3}>
                            <p className='color4 pad-xs'>As vitaminas e minerais são fundamentais para o corpo conseguir usar a energia proveniente dos alimentos. </p>
                        </td>
                    </tr>
                    <tr>
                        <td className='background4  colorWhite center bold' colSpan={2}>VITAMINA D</td>
                    </tr>

                    <tr>
                        <td className='background4  colorWhite  center bold' colSpan={2}>CÁLCIO</td>
                    </tr>

                    <tr>
                        <td className='background4  colorWhite  center bold' colSpan={3}>MAGNESIO</td>
                    </tr>
                    <tr>
                        <td className='background5' rowSpan={5}></td>
                        <td className='background5' rowSpan={4}></td>
                        <td className='background4  colorWhite  center bold' >VITAMINA C</td>
                    </tr>

                    <tr>

                        <td className='background4  colorWhite  center bold' >VITAMINA B1</td>
                    </tr>

                    <tr>

                        <td className='background4  colorWhite  center bold' >VITAMINA B3</td>
                    </tr>
                    <tr>

                        <td className='background4  colorWhite  center bold' >VITAMINA B5</td>
                    </tr>

                    <tr>

                        <td className='background4 colorWhite  center bold' >VITAMINA B12</td>
                        <td className='background5' rowSpan={2}> </td>
                    </tr>

                    <tr>

                        <td className='background4  colorWhite  center bold' colSpan={2} >PROTEÍNA</td>

                    </tr>

                </tbody>
            </table>


        </div>
        <br />

    </div>


}

export default MixDeNutrientes;