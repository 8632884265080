import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,

} from './styles';



import { useAuth } from '../../hooks/Auth';

import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';

import api from '../../services/api';
import { hash, urlWebsite } from '../../config';

import { loadFromJson } from '../../utils/loadFromJson';
import { relative } from 'path';
import Slider from 'react-slick';
import InformacoesNutrucionais from './modules/InformacoesNutricionais';
import LeiteEmPo from './modules/LeiteEmPo';



interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Dicas: React.FC = () => {

  const { setMenu } = usePages();
  const { setAvailableLanguages } = useLanguage();
  const { translate } = useLanguage();
  const lorem = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
  const { addModal, removeModal } = useModal();
  const [slides, setSlides] = useState<Array<JSX.Element>>([]);
  const { user } = useAuth();





  return (<>
    <img src="/apoio/cabecalho-leite.png" style={{ width: '100%', zIndex: 10000 }} />



    <Container className=' column pad-sm fadeIn' style={{ background: 'none', zIndex: 900 }}>
      <h2 className='hugeTitle2  colorWhite'>
        COMO É FEITO
      </h2>

      <div className='  background2 max-400 color1 pad-xs  rotinaAlimentarTitle  ' >
        Maneira CCGL de produzir! Tudo feito com muito carinho, respeito e comprometimento com as pessoas.

      </div>

      <br /><br />

      <div className='max-1200 pad' style={{ background: '#fff' }}>
        <img src="/apoio/ordenha.png" className='full-width' />
      </div>

      <br /><br />

    </Container>


    <div className=' row-to-column gap pad-sm fadeIn' style={{ alignItems: 'flex-start' }} >

      <InformacoesNutrucionais />

      <div className='column max-600'>
        <h2 className='hugeTitle2  colorWhite'>

        </h2>


        <LeiteEmPo />

        <p className='midTitle2 colorWhite pad-sm' >
          Unimos o delicioso sabor do leite com a praticidade do pó em um produto com alto valor nutricional.
          <br /><br />
          Com apenas <span className='color2'>UM COPO (200 ml)</span> do novo leite em pó CCGL é possível obter <strong className="color2">12 GRAMAS DE PROTEÍNA</strong>, o que representa 24% das recomendações de ingestão diária
          <br /><br />
          (com base em uma dieta de 2000 kcal).

        </p>

        <div className='row gap-sm'>

          <div className='blueBall'>
            <h2  >
              RICO EM PROTEÍNAS COM 12 GRAMAS DE PROTEÍNA POR PORÇÃO!

            </h2>
          </div>

          <div className='blueBall'>
            <h2  >
              LIVRE DE GORDURAS TOTAIS E SATURADAS!

            </h2>
          </div>

          <div className='blueBall'>
            <h2  >
              RICO EM: vitamina D, vitamina C e cálcio e FONTE DE vitamina B1, B3, B5, B12 e magnésio!
            </h2>
          </div>
        </div>
      </div>
    </div>
    <img src="/apoio/rodape-leite.png" style={{ width: '100%' }} />

  </>
  );
};
export default Dicas;
