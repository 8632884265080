import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,

} from './styles';



import { useAuth } from '../../hooks/Auth';

import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';

import api from '../../services/api';
import { hash, urlWebsite } from '../../config';

import { loadFromJson } from '../../utils/loadFromJson';
import LeiteEmPo from '../ComoEFeito/modules/LeiteEmPo';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Vitalidade: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const data = [

    { img_style: { width: '70px' }, icon: '/apoio/pro/proteina.png', title: 'PROTEÍNA PARA MANUTENÇÃO MUSCULAR', description: 'A proteína é a aliada fundamental na preservação da massa muscular. O leite em pó CCGL oferece uma dose significativa de nutrientes. Estudos indicam que a ingestão adequada de proteínas é vital para a saúde muscular, especialmente para indivíduos mais maduros. Combinado com a vitamina B12, nosso produto otimiza a síntese de proteínas, promovendo a manutenção muscular, o que é essencial para manter a funcionalidade, a mobilidade e a força muscular ao longo do tempo. Estudos demonstram que a manutenção muscular também está relacionada com uma vida mais prolongada, com mais autonomia e função cognitiva melhorada, auxiliando na prevenção de declínios relacionados à idade.' },
    { img_style: { width: '60px' }, icon: '/apoio/pro/osso.png', title: 'SAÚDE ÓSSEA', description: 'Sabemos que a manutenção óssea é crucial para uma longevidade ativa. <br/>    Por isso, além de proteínas, nosso leite em pó é enriquecido com vitamina D, cálcio e magnésio, elementos com papel crucial na saúde óssea, especialmente durante o envelhecimento. Essa combinação oferece um ótimo suporte para ossos fortes e resilientes, sendo um investimento para uma mobilidade contínua, estabilidade e prevenção de doenças relacionadas aos ossos.' },
    { img_style: { width: '60px' }, icon: '/apoio/pro/coracao.png', title: 'ENERGIA SUSTENTÁVEL PARA UMA VIDA ATIVA', description: 'A vitalidade não está apenas na preservação física, mas também na energia que nos impulsiona. Nosso leite em pó é enriquecido com magnésio e vitaminas C, B1, B2, B3 e B5, elementos fundamentais para a produção de energia. Isso não apenas mantém você ativo, mas também proporciona uma sensação duradoura de vitalidade para enfrentar suas atividades diárias.' },
    { img_style: { width: '60px' }, icon: '/apoio/inteligencia.png', title: 'A ESCOLHA INTELIGENTE PARA UMA MATURIDADE ATIVA', description: 'Nosso Leite em Pó Proteico não é apenas uma bebida; é uma escolha inteligente para quem deseja envelhecer com saúde, mantendo a vitalidade e a plenitude, cuidando da sua saúde muscular e óssea. Apoie seu corpo com o que a ciência e a natureza têm de melhor. APROVEITE CADA MOMENTO DA VIDA ATIVA' },
    { img_style: { width: '30px' }, icon: '/apoio/aproveite.png', title: 'APROVEITE CADA MOMENTO DA VIDA ATIVA', description: 'Cada gole do nosso leite em pó é uma celebração da vida ativa. Envolva-se na jornada de vida com força, vitalidade, saúde e o apoio necessário para desfrutar de cada momento.' },

  ]
  const currentData = data?.[currentIndex] || {};

  return (<>


    <Container className='gap-bg column  ' style={{ minHeight: '80vh', background: 'none', zIndex: 900 }}>

      <div className='row full-width fadeIn '>
        <img loading='lazy' src='/apoio/vitalidade/ccgl.png' className='max-400' />
      </div>
      <div className='max-1400 gap-bg column fadeIn ' style={{ marginTop: '50px' }}>
        <img loading='lazy' src='/apoio/pro/imagens-pagina5.png' className='max-1400 computerOnly' />
        <img loading='lazy' src='/apoio/pro/pag5-mobile.png' className='max-1400 mobileOnly' />





        <div className='row full-width fadeIn '>
          <img loading='lazy' src='/apoio/vitalidade/VITALIDADE-.png' className='max-1200' />


        </div>
        <p className='contentParagraphSimple text-center max-1200'>
          À medida que abraçamos a maturidade, a busca por uma vida saudável e plena se torna uma prioridade ainda maior. Apresentamos nosso Leite em Pó Proteico, uma fonte completa de nutrientes projetada especificamente para nutrir não apenas o corpo, mas a vitalidade que define cada passo da sua rotina.
        </p>
        <div className='column computerOnlyFlex'>

          {data?.map(item => {
            return <div className='row fadeIn   row-start row-to-column max-1200 gap-md pad-sm' style={{ alignItems: 'flex-start' }}>
              <div style={{ width: '70px', minWidth: '70px', display: 'flex', marginTop: '7px', alignItems: 'flex-start', justifyContent: 'center' }}><img loading='lazy' className='max-100' style={{ ...item?.img_style }} src={item?.icon} /></div>
              <p className='contentParagraphSimple text-left max-1200'>
                <strong className='color2'>{item?.title}</strong> <br />
                <p dangerouslySetInnerHTML={{ __html: item?.description }} />  </p>
            </div>


          })}

        </div>

        <div className='mobileOnlyFlex column'>

          <div className='row gap-xs'>

            {data?.map((item, index) => {

              return <div style={{ borderRadius: '7.5px', width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`pad-xs fadeIn  ${index === currentIndex ? 'background1 ' : 'none'}`}>
                <img loading='lazy' onClick={() => setCurrentIndex(index)} style={{ maxHeight: '50px', width: 'auto' }} src={item?.icon} /></div>
            })}


          </div>

          <div key={currentData?.title} className='row fadeIn  row-start rcolumn max-1000 gap-md pad-sm'>
            <p className='contentParagraphSimple text-left max-1000'>
              <strong className='color2'>{currentData?.title}</strong> <br />
              <p  > {currentData?.description}</p></p>
          </div>


        </div>


      </div>



    </Container>

  </>
  );
};
export default Vitalidade;
