export const energiaData = [

    { img_style: { width: '45px' }, icon: '/apoio/pro/energia.png', title: 'ENERGIA PARA SUA VIDA', description: 'Cada porção do leite em pó CCGL é enriquecida com nutrientes (magnésio, vitamina B1, vitamina B3, vitamina B5) que estão relacionados ao metabolismo de energia. Ou seja, ajudam a manter o funcionamento normal do seu metabolismo de energia, acompanhando seu ritmo acelerado!' },
    {
        img_style: { width: '70px' }, icon: '/apoio/flexibilidade.png', title: 'FLEXIBILIDADE NA ROTINA', description: 'A sua vida é uma jornada movimentada e sabemos que sua nutrição deve ser tão adaptável quanto você. Nosso leite em pó é a solução prática e versátil para suas necessidades nutricionais, seja em casa, na academia ou no trânsito. Apenas um shake, misturando o leite em pó com água, e você está pronto para conquistar o dia.'
    },
    { img_style: { width: '70px' }, icon: '/apoio/pro/recuperacao.png', title: 'RECUPERAÇÃO RÁPIDA PARA O SEU DIA A DIA', description: 'Sabemos que a recuperação é crucial para sustentar uma vida ativa. Com uma mistura equilibrada de proteínas de qualidade e outros nutrientes, nosso leite em pó auxilia na recuperação muscular, garantindo que você esteja pronto para enfrentar cada desafio, mantendo o equilíbrio entre trabalho, diversão, esporte e bem-estar.' },
    { img_style: { width: '60px' }, icon: '/apoio/nutrido.png', title: 'MANTENHA-SE NUTRIDO, MESMO EM MOVIMENTO', description: 'A vida ativa não precisa significar comprometer a nutrição. Com nosso leite em pó, você pode continuar a enfrentar o mundo, sabendo que está dando ao seu corpo o apoio proteico e a saciedade necessários para seguir em frente. Além de diversos outros nutrientes que contribuem com a sua energia e saúde óssea.' },
    { img_style: { width: '50px' }, icon: '/apoio/multitarefa.png', title: 'PARA OS MULTITAREFADOS E OS ACELERADOS', description: 'Seja você um multitarefa profissional, um entusiasta do fitness com uma agenda lotada ou alguém que simplesmente abraça o caos controlado da vida, O LEITE EM PÓ PROTEICO CCGL ESTÁ AQUI PARA SER O APOIO ESSENCIAL NO SEU DIA A DIA.' },

]