import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,

} from './styles';



import { useAuth } from '../../hooks/Auth';

import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';

import api from '../../services/api';
import { hash, urlWebsite } from '../../config';

import { loadFromJson } from '../../utils/loadFromJson';
import IframePlayer from '../../components/IframePlayer';
import { massaMuscular } from './data/massaMuscular';
import { energiaData } from './data/energia';
import { vitalidadeData } from './data/vitalidade';
import { CalcioSaudeOssea, MagnesioSaudeOssea, ProteinaSaudeOssea, VitaminaDSaudeOssea } from './modules/Contents/SaudeOssea';
import { CalcioSaudeMuscular, MagnesioSaudeMuscular, ProteinaSaudeMuscular, VitaminaB12SaudeMuscular, VitaminaDSaudeMuscular } from './modules/Contents/SaudeMuscular';
import { MagnesioEnergia, VitaminaB1Energia, VitaminaB3Energia, VitaminaB5Energia, VitaminaCEnergia } from './modules/Contents/Energia';
import { scrollToID } from '../../utils/scrollTo';
import InformacoesNutrucionais from '../ComoEFeito/modules/InformacoesNutricionais';
import MenuMain from './modules/Menu';
import AccessibilityMenu from './modules/AccessibilityMenu';
import Dicas from '../Dicas';
import Particles from 'react-particles-js';


interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home2: React.FC = () => {

  const { setMenu } = usePages();
  const { setAvailableLanguages } = useLanguage();
  const { translate } = useLanguage();
  const lorem = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
  const { addModal, removeModal } = useModal();
  const [slides, setSlides] = useState<Array<JSX.Element>>([]);
  const { user } = useAuth();

  const [search, setSearch] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const sendToEvents = () => {

    let link = currentCategory ? `/${currentCategory}` : `/eventos`;

    if (search) {
      link = `${link}?search=${encodeURIComponent(search)}`;
    }

    window.location.href = link;

  }

  useEffect(() => {

    if (currentCategory) {
      window.location.href = currentCategory ? `/${currentCategory}` : `/eventos`;
    }

  }, [currentCategory])

  const loadConfig = async () => {
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }


    if (data.slides) {
      const slideJSX: Array<JSX.Element> = [];

      data.slides.map(s => {
        slideJSX.push(s.link_url ? <a href={s.link_url}><img loading='lazy' src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} /></a> : <img loading='lazy' src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} />)
      })

      setSlides(slideJSX);

      if (data?.popup_status === 'yes' && data?.popup_images && data?.popup_images?.length > 0) {

        if (user && user.updated_information !== 'yes' && user.profile === 2) {

        }
        else {
          addModal({
            key: 'popup',
            theme: 'whiteModalCapa',
            title: ' ',
            content: <><div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>
              <h2 style={{ padding: '10px', width: '100%', textAlign: 'center', color: 'rgb(0,14,64)' }}>Clique na imagem para acessar</h2>
              {data?.popup_images.map(popup => {
                return <a onClick={() => removeModal('popup')} key={`popup-url-${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '15px auto' }} href={`${popup?.link_url}`}>
                  <img loading='lazy' src={`${urlWebsite}/${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }} />
                </a>

              })}

            </div></>
          })

        }


      }

    }

    const menu: Array<IMenu> = [];

    try {
      data?.menu.map((m: IMenu) => {
        m.fluid = parseInt(m.fluid.toString(), 10);
        m.status = parseInt(m.status.toString(), 10);
        m.external = parseInt(m.external.toString(), 10);

        menu.push(m);
      })

      setMenu(menu);

    }
    catch (err) {
      console.log('Error on define Menu');
    }


    setAvailableLanguages(data?.languages || []);




  }

  useEffect(() => {
    loadConfig();
    /*
        addModal({
       key:'popup',
       theme: 'whiteModal2',
       title:' ',
       content: <><div style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}>
         <Link onClick={() => removeModal('popup')} style={{width:'100%', maxWidth:'500px', margin: '0px auto'}} to="/app/sessao-clinica-hospitais-dasa-brasil/login">
           <img  loading='lazy' src="/apoio/sessao-clinica.png" style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}/>
         </Link>
         </div></>
     }  ) */
  }, [])


  const handle = () => { }

  return (<>
    <AccessibilityMenu />
    <MenuMain />
    <Container id='home' className='gap column fundoCapa fadeIn' style={{ minHeight: '100vh', zIndex: 950 }}>

      <div className='row full-width'>
        <div className='product max-1200 ' style={{ position: 'relative' }}>
          {/*   <Particles
            params={{
              particles: {
                lineLinked: {
                  enable: false,
                  distance: 250
                },
                move: {
                  speed: 5,
                  direction: 'top',
                  collisions: false,
                  outModes: {
                    default: 'out',
                    left: 'out',
                    right: 'out',
                    bottom: 'out',
                    top: 'out',
                  },


                },
                opacity: {
                  value: 0.6,
                  random: true,
                  animation: {
                    enable: true
                  }

                },
                shape: {
                  type: 'square',

                },

                color: { value: 'rgb(250,200,0)' },

                number: {
                  density: {
                    enable: true,
                    value_area: 200,
                  },
                  value: 20,
                },
                size: {
                  value: 15,
                  random: true,
                },
              },
            }}
            className={"particles-wrapper"}
          /> */}
          <div className='productImage ' style={{ position: 'relative' }}>

            <img loading='lazy' src={window?.innerWidth > window?.innerHeight ? "/apoio/pro/embalagem.png" : "/apoio/pro/embalagem-mobile.png"} style={{ zIndex: 2 }} />



          </div>
          <div className='productContent'>
            <img loading='lazy' src="/apoio/Topo2.png" />
            <p className='notificacaoMinisterio computerOnly'>
              "O <strong style={{ color: '#fff' }}>MINISTÉRIO DA SAÚDE INFORMA</strong >: O ALEITAMENTO MATERNO EVITA <br />INFECÇÕES E ALERGIAS E É RECOMENDADO ATÉ OS 2 (DOIS) ANOS DE IDADE OU MAIS".
            </p>
            <p className='notificacaoMinisterio mobileOnly'>
              "O <strong style={{ color: '#fff' }}>MINISTÉRIO DA SAÚDE INFORMA</strong >: O ALEITAMENTO MATERNO EVITA INFECÇÕES E ALERGIAS E É RECOMENDADO ATÉ OS 2 (DOIS) ANOS DE IDADE OU MAIS".
            </p>
          </div>
        </div>


      </div>



    </Container>
    <div id='video-lancamento' className='full-width row' style={{ background: '#eee7de' }}>
      <Container className='gap column  fadeIn max-1000' style={{ zIndex: 950, }}>
        {/* <h2 className='hugeTitle2 text-center colorPrimary'>
          ASSISTA AO VÍDEO DE LANÇAMENTO

        </h2> */}
        <IframePlayer keyName='video-lancamento' id='https://www.youtube.com/embed/XOhhnpdnKVI' />
      </Container>
    </div>

    <div className='full-width row row-to-column pad-bg' tabIndex={0}>
      <Container className='  column  fadeIn max-1000 ' style={{ zIndex: 950, padding: '30px', minHeight: '0px' }}>
        <h2 className='hugeTitle2 colorWhite'>
          PARA QUEM É ESTE LEITE?

        </h2>
      </Container>



      <div className='full-width row  gap' style={{ alignItems: 'flex-start' }} tabIndex={0}>
        <div tabIndex={0} className='full-width' onKeyDown={(event) => {
          if (event.key === 'Enter') {
            scrollToID('ganho-muscular')
          }
        }} onClick={() => scrollToID('ganho-muscular')}>
          <div className='scaleOnHover  cursor' role="img" aria-label="Homem concentrado fazendo roupa preta fazendo treino de cordas na academia." style={{ background: 'url("/apoio/v3/Rapaz.jpg")', backgroundPosition: 'center', width: '100%', height: '30vh', backgroundSize: 'cover' }}></div>
          <h2 className='colorWhite  pad-sm row full-width midTitle3  text-center' >MÚSCULO</h2>
        </div>

        <div tabIndex={0} className='full-width' onKeyDown={(event) => {
          if (event.key === 'Enter') {
            scrollToID('energia')
          }
        }} onClick={() => scrollToID('energia')}>
          <div className='scaleOnHover  cursor' role="img" aria-label="Mulher, feliz, em boa forma e fazendo corrida ao ar livre." style={{ background: 'url("/apoio/v3/Mulher.jpg")', backgroundPosition: 'center', width: '100%', height: '30vh', backgroundSize: 'cover' }}></div>
          <h2 className='colorWhite  pad-sm row full-width midTitle3  text-center'>ENERGIA</h2>
        </div>

        <div tabIndex={0} className='full-width' onKeyDown={(event) => {
          if (event.key === 'Enter') {
            scrollToID('vitalidade')
          }
        }} onClick={() => scrollToID('vitalidade')}>
          <div className='scaleOnHover cursor' role="img" aria-label="Mulher com aproximadamente 70 anos, feliz, boa forma e andando de bicicleta ao ar livre." style={{ background: 'url("/apoio/v3/Senhora.jpg")', backgroundPosition: 'center', width: '100%', height: '30vh', backgroundSize: 'cover' }}></div>
          <h2 className='colorWhite  pad-sm row full-width midTitle3 text-center'>VITALIDADE</h2>
        </div>
      </div>
    </div>
    {/*  <Container className='gap column ' style={{ minHeight: '80vh', background: '#4268b0', zIndex: 900 }}>

      <div className='max-1200 gap-bg column'>
        <img loading='lazy' src='/apoio/pro/super-beneficios.png' className='max-600' />

        <img loading='lazy' src='/apoio/pro/ICONS.png' className='max-900' />

        <p className='contentParagraph uppercase max-800 computerOnly'>
          A partir da vitamina D, do cálcio, do magnésio<br />
          e da proteína, <strong>o novo leite em pó da CCGL</strong> contribui
          para a saúde óssea e saúde muscular.
          <br /><br />
          E claro, com as <strong>vitaminas C, B1, B3 e B5</strong>,<br />
          contribui para a sua energia.

        </p>
        <p className='contentParagraph uppercase max-800 mobileOnly'>
          A partir da vitamina D, do cálcio, do magnésio
          e da proteína, <strong>o novo leite em pó da CCGL</strong> contribui
          para a saúde óssea e saúde muscular.
          <br /><br />
          E claro, com as <strong>vitaminas C, B1, B3 e B5</strong>,
          contribui para a sua energia.

        </p>

      </div>



  </Container> 
    <img loading='lazy' src="/apoio/pro/cortado.png" style={{ width: '100%', marginTop: '-5px' }} />*/}


    <div className='full-width' id='ganho-muscular' style={{ position: 'relative' }} tabIndex={0}>
      <div role="img" aria-label="Homem concentrado com roupa preta fazendo treino de cordas na academia" className='' style={{ background: 'url("/apoio/v3/Rapaz.jpg")', backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.15 }}></div>




      <Container className='gap-bg row-to-column fadeIn max-1200' style={{ minHeight: '80vh', paddingTop: '400px', background: 'none', zIndex: 900 }}>

        <div className='full-width max-1000'>

          <h2 className='hugeTitle2 colorWhite max-800' style={{ textAlign: 'left' }}>
            PARA QUEM AMA FAZER EXERCÍCIOS, TEM FOCO NO MÁXIMO
            DESEMPENHO E GANHO DE MASSA MUSCULAR
          </h2>

          <p className='contentParagraphSimple' style={{ textAlign: 'left', margin: '15px 0px' }}>
            Para você que pratica exercícios e busca ganho muscular, o leite em pó proteico CCGL é uma fonte poderosa com 12 gramas de proteína por porção, enriquecido com nutrientes essenciais para impulsionar seu desempenho atlético a novos patamares.
          </p>
          <div className='full-width row row-start flex-wrap gap-sm' style={{ marginTop: '5px', alignItems: 'stretch' }}>
            {massaMuscular?.map(item => {
              return <div tabIndex={0} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', content: <>
                      <strong className='color2 midTitle2 text-center'>{item?.title}</strong> <br />
                      <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </>, theme: 'blackModalMedium'

                  })
                }
              }} onClick={() => addModal({
                title: '', content: <>
                  <strong className='color2 midTitle2 text-center'>{item?.title}</strong> <br />
                  <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </>, theme: 'blackModalMedium'
              })} className='row fadeIn   row row-end  gap-md pad-sm scaleOnHover cursor blackBackground' style={{ alignItems: 'center', width: 'auto' }}>
                <div style={{ width: '60px', minWidth: '60px', display: 'flex', marginTop: '7px', alignItems: 'flex-start', justifyContent: 'center' }}><img loading='lazy' className='max-100' style={{ ...item?.img_style }} src={item?.icon} /></div>

                <h2 className='color2  midTitle4 max-100' style={{ width: 'auto' }}>{item?.title}</h2> <br />

              </div>


            })}
          </div>

        </div>




      </Container>
    </div>


    <div className='full-width' id='energia' style={{ position: 'relative', marginTop: '50px' }} tabIndex={0}>
      <div className='' role="img" aria-label="Mulher, feliz, em boa forma e fazendo corrida ao ar livre." style={{ background: 'url("/apoio/v3/Mulher.jpg")', backgroundPosition: 'top', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.15 }}></div>


      <Container className='gap-bg row-to-column fadeIn max-1400' style={{ minHeight: '80vh', paddingTop: '400px', background: 'none', zIndex: 900 }}>

        <div className='full-width max-1000'>

          <h2 className='hugeTitle2 colorWhite max-800' style={{ textAlign: 'left' }}>
            PARA QUEM TEM UM ESTILO DE VIDA ATIVO E PRECISA DE ENERGIA PARA SE MANTER EM MOVIMENTO

          </h2>

          <p className='contentParagraphSimple max-800' style={{ textAlign: 'left', margin: '15px 0px' }}>

            Caro amante de um estilo de vida ativo, se a sua vida é uma constante dança entre os compromissos diários, o trabalho, os treinos e a agitação constante, temos a solução ideal para manter sua energia em alta e nutrir seu corpo nos momentos mais movimentados. Apresentamos o nosso Leite em Pó Proteico  CCGL - a resposta para você, que não para!</p>
          <div className='full-width row row-start flex-wrap  gap-sm' style={{ marginTop: '5px', alignItems: 'stretch' }}>
            {energiaData?.map(item => {
              return <div tabIndex={0} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', content: <>
                      <strong className='color2 midTitle2 text-center'>{item?.title}</strong> <br />
                      <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </>, theme: 'blackModalMedium'

                  })
                }
              }} onClick={() => addModal({
                title: '', content: <>
                  <strong className='color2 midTitle2 text-center'>{item?.title}</strong> <br />
                  <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </>, theme: 'blackModalMedium'
              })} className='row fadeIn   row row-end  gap-md pad-sm scaleOnHover cursor blackBackground' style={{ alignItems: 'center', width: 'auto' }}>
                <div style={{ width: '60px', minWidth: '60px', display: 'flex', marginTop: '7px', alignItems: 'flex-start', justifyContent: 'center' }}><img loading='lazy' className='max-100' style={{ ...item?.img_style }} src={item?.icon} /></div>

                <h2 className='color2  midTitle4 max-100' style={{ width: 'auto', maxWidth: '110px' }}>{item?.title}</h2> <br />

              </div>


            })}
          </div>

        </div>




      </Container>

    </div>

    <div className='full-width' id='vitalidade' style={{ position: 'relative', marginTop: '50px' }} tabIndex={0}>
      <div role="img" aria-label="Mulher com aproximadamente 70 anos, feliz, boa forma e andando de bicicleta ao ar livre." className='' style={{ background: 'url("/apoio/v3/Senhora.jpg")', backgroundPosition: 'top', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.15 }}></div>


      <Container className='gap-bg row-to-column fadeIn max-1400' style={{ minHeight: '80vh', paddingTop: '400px', background: 'none', zIndex: 900 }}>

        <div className='full-width max-1000'>

          <h2 className='hugeTitle2 colorWhite max-800' style={{ textAlign: 'left' }}>
            PARA QUEM BUSCA UMA VIDA SAUDÁVEL
            E REPLETA DE VITALIDADE

          </h2>

          <p className='contentParagraphSimple max-800' style={{ textAlign: 'left', alignSelf: 'flex-start', margin: '15px 0px' }}>
            Vitalidade para uma vida plena: Leite em pó proteico para uma geração ativa e saudável.
            À medida que abraçamos a maturidade, a busca por uma vida saudável e plena se torna uma prioridade ainda maior. Apresentamos nosso Leite em Pó Proteico, uma fonte completa de nutrientes projetada especificamente para nutrir não apenas o corpo, mas a vitalidade que define cada passo da sua rotina.
          </p>
          <div className='full-width row row-start flex-wrap gap-sm' style={{ marginTop: '5px', alignItems: 'stretch' }}>
            {vitalidadeData?.map(item => {
              return <div tabIndex={0} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({

                    title: '', content: <>
                      <strong className='color2 midTitle2 text-center'>{item?.title}</strong> <br />
                      <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </>, theme: 'blackModalMedium'

                  })
                }
              }} onClick={() => addModal({
                title: '', content: <>
                  <strong className='color2 midTitle2 text-center'>{item?.title}</strong> <br />
                  <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </>, theme: 'blackModalMedium'
              })} className='row fadeIn   row row-end gap-md pad-sm scaleOnHover cursor blackBackground' style={{ alignItems: 'center', width: 'auto' }}>
                <div style={{ width: '60px', minWidth: '60px', display: 'flex', marginTop: '7px', alignItems: 'flex-start', justifyContent: 'center' }}><img loading='lazy' className='max-100' style={{ ...item?.img_style }} src={item?.icon} /></div>

                <h2 className='color2  midTitle4 max-100' style={{ width: 'auto' }}>{item?.title}</h2> <br />

              </div>


            })}
          </div>

        </div>




      </Container>

    </div>

    <div id='mix-nutricional' className='full-width row row-to-column pad-bg' tabIndex={0}>
      <Container className='  column  fadeIn max-600 gap-sm ' style={{ zIndex: 950, minHeight: '0px', margin: '15px' }}>
        <h2 className='hugeTitle2 colorWhite'>
          ENTENDA O PAPEL<br /> do nosso mix de <br />NUTRIENTES

        </h2>

        <div className='column gap-sm max-400'>

          <h2 tabIndex={0} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              scrollToID('saude-ossea')
            }
          }} onClick={() => scrollToID('saude-ossea')} className='colorWhite blackBackground color2  pad-sm row full-width   max-400 midTitle3 text-center'>SAÚDE ÓSSEA</h2>

          <h2 tabIndex={0} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              scrollToID('saude-muscular')
            }
          }} onClick={() => scrollToID('saude-muscular')} className='colorWhite blackBackground color2  pad-sm row full-width midTitle3  max-400 text-center'>SAÚDE MUSCULAR</h2>

          <h2 tabIndex={0} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              scrollToID('saude-energia')
            }
          }} onClick={() => scrollToID('saude-energia')} className='colorWhite blackBackground color2  pad-sm row full-width midTitle3   max-400 text-center'>ENERGIA</h2>
        </div>
      </Container>

      <img className='computerOnly' src='/apoio/pro/embalagem.png' style={{ width: '100%', maxWidth: '400px' }} />

    </div>

    <div className='row flex-wrap gap-sm full-width' style={{ alignItems: 'stretch' }} tabIndex={0}>
      <div id="saude-ossea" className='max-400 row row-to-column pad-sm' style={{ position: 'relative', paddingTop: '100px', alignItems: 'center', background: 'rgba(50,50,50,0.3)' }}>

        <div className='' style={{ background: 'url("apoio/v3/SAUDEOSSEA.jpg")', backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.3 }}></div>


        <Container tabIndex={0} className='  column  fadeIn max-600 gap-sm ' style={{ zIndex: 950, minHeight: '0px', alignSelf: 'flex-end', margin: '0px' }}>
          <h2 className='hugeTitle3 colorWhite'>
            SAÚDE ÓSSEA

          </h2>

          <div className='column column-start gap-sm max-400' style={{ margin: '0px' }}>

            <h2 tabIndex={0}
              onClick={() => {
                addModal({
                  title: '', theme: 'blackModal', content: <VitaminaDSaudeOssea />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaDSaudeOssea />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width cursor scaleOnHover    max-400 midTitle3 text-center'>VITAMINA D</h2>

            <h2 tabIndex={0} onClick={() => {
              addModal({
                title: '', theme: 'blackModal', content: <CalcioSaudeOssea />
              })
            }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <CalcioSaudeOssea />
                  })
                }
              }}

              className='colorWhite blackBackground color2  pad-sm row full-width midTitle3 cursor scaleOnHover   max-400 text-center'>CÁLCIO</h2>

            <h2 tabIndex={0} onClick={() => {
              addModal({
                title: '', theme: 'blackModal', content: <MagnesioSaudeOssea />
              })
            }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <MagnesioSaudeOssea />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width midTitle3 cursor scaleOnHover    max-400 text-center'>MAGNÉSIO</h2>

            <h2 tabIndex={0} className='colorWhite blackBackground color2  pad-sm row full-width midTitle3 cursor scaleOnHover   max-400 text-center'
              onClick={() => {
                addModal({
                  title: '', theme: 'blackModal', content: <ProteinaSaudeOssea />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <ProteinaSaudeOssea />
                  })
                }
              }}
            >PROTEÍNA</h2>

            <h2 style={{ minHeight: '57px' }} className='colorWhite color2  pad-sm row full-width midTitle3 cursor scaleOnHover   max-400 text-center'

            > </h2>
          </div>
        </Container>


      </div>

      <div tabIndex={0} id="saude-muscular" className='max-400 row row-to-column pad-sm' style={{ position: 'relative', paddingTop: '100px', alignItems: 'center', background: 'rgba(50,50,50,0.3)' }}>

        <div className='' style={{ background: 'url("apoio/v3/SAUDEMUSCULAR.jpg")', backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.3 }}></div>


        <Container className='  column  fadeIn max-600 gap-sm ' style={{ zIndex: 950, minHeight: '0px', alignSelf: 'flex-end', margin: '0px' }}>
          <h2 className='hugeTitle3 colorWhite'>
            SAÚDE MUSCULAR

          </h2>

          <div className='column gap-sm max-400' style={{ margin: '0px' }}>
            <h2 tabIndex={0} onClick={() => {

              addModal({
                title: '', theme: 'blackModal', content: <VitaminaDSaudeMuscular />
              })
            }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaDSaudeMuscular />
                  })
                }
              }}

              className='colorWhite blackBackground color2  pad-sm row full-width   max-400 cursor scaleOnHover  midTitle3 text-center'>VITAMINA D</h2>

            <h2 tabIndex={0} onClick={() => {

              addModal({
                title: '', theme: 'blackModal', content: <ProteinaSaudeMuscular />
              })
            }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <ProteinaSaudeMuscular />
                  })
                }
              }}

              className='colorWhite blackBackground color2  pad-sm row full-width   max-400 cursor scaleOnHover  midTitle3 text-center'>PROTEÍNA</h2>
            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <MagnesioSaudeMuscular />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <MagnesioSaudeMuscular />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width midTitle3  cursor scaleOnHover  max-400 text-center'>MAGNÉSIO</h2>
            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <CalcioSaudeMuscular />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <CalcioSaudeMuscular />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width midTitle3  cursor scaleOnHover  max-400 text-center'>CÁLCIO</h2>



            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <VitaminaB12SaudeMuscular />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaB12SaudeMuscular />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width midTitle3 cursor scaleOnHover    max-400 text-center'>VITAMINA B12</h2>


          </div>
        </Container>


      </div>

      <div tabIndex={0} id="saude-energia" className='max-400 row row-to-column pad-sm' style={{ position: 'relative', paddingTop: '150px', alignItems: 'center', background: 'rgba(50,50,50,0.3)' }}>

        <div className='' style={{ background: 'url("apoio/v3/ENERGIA.jpg")', backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.3 }}></div>


        <Container className='  column  fadeIn max-600 gap-sm ' style={{ zIndex: 950, minHeight: '0px', alignSelf: 'flex-end' }}>
          <h2 className='hugeTitle3 colorWhite'>
            ENERGIA

          </h2>

          <div className='column gap-sm max-400' style={{ margin: '0px' }}>

            <h2 tabIndex={0} onClick={() => {

              addModal({
                title: '', theme: 'blackModal', content: <MagnesioEnergia />
              })
            }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <MagnesioEnergia />
                  })
                }
              }}

              className='color2 blackBackground color2   pad-sm row full-width midTitle3 cursor scaleOnHover   max-400 text-center'>MAGNÉSIO</h2>

            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <VitaminaB1Energia />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaB1Energia />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width   max-400 midTitle3 cursor scaleOnHover text-center'>VITAMINA B1</h2>
            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <VitaminaB3Energia />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaB3Energia />
                  })
                }
              }}
              className='colorWhite blackBackground color2  pad-sm row full-width   max-400 midTitle3 cursor scaleOnHover text-center'>VITAMINA B3</h2>
            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <VitaminaB5Energia />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaB3Energia />
                  })
                }
              }}

              className='colorWhite blackBackground color2  pad-sm row full-width   max-400 midTitle3 cursor scaleOnHover text-center'>VITAMINA B5</h2>



            <h2 tabIndex={0}
              onClick={() => {

                addModal({
                  title: '', theme: 'blackModal', content: <VitaminaCEnergia />
                })
              }}

              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  addModal({
                    title: '', theme: 'blackModal', content: <VitaminaCEnergia />
                  })
                }
              }}

              className='colorWhite blackBackground color2  pad-sm row full-width midTitle3 cursor scaleOnHover  max-400 text-center'>VITAMINA C</h2>


          </div>
        </Container>


      </div>
    </div>

    <div tabIndex={0} className='full-width row row-to-column' style={{ position: 'relative' }}>
      <div className='full-width row row-to-column max-1200' style={{ alignItems: 'center', position: 'relative' }}>
        <div className='column max-300'>
          <img src='/apoio/v3/embalagem-sem-borda.png' style={{ width: '100%', maxWidth: '300px' }} />
        </div>
        <Container id='rendimento' className='gap-bg row-to-column fadeIn max-1200' style={{ minHeight: '0vh', background: 'none', zIndex: 900, alignItems: 'flex-start' }}>


          <div className='full-width max-1000'>

            <h2 className='hugeTitle2 color2 max-800' style={{ textAlign: 'left' }}>
              RENDIMENTO
            </h2>

            <p className='contentParagraphSimple gap-sm' style={{ textAlign: 'left', marginTop: '15px' }}>
              Uma embalagem com <strong className='color2'>270 gramas</strong> rende <nav className="gap-sm background2 " style={{ display: 'flex', width: 'auto', maxWidth: '320px', alignItems: 'center' }}  >
                <img style={{ width: '200px' }} src='/apoio/v3/rendimento.png' /> <strong className='color3'>9 porções</strong></nav> com <strong className='color2'>12g de proteína!</strong>
            </p>
            <p className='contentParagraphSimple gap-sm' style={{ textAlign: 'left', marginTop: '15px' }}>
              O que representa 24% das recomendações de ingestão diária
              (com base em uma dieta de 2000 kcal).
            </p>



          </div>




        </Container>
        <Container id='informacoes-nutricionais' className='max-500'>
          <InformacoesNutrucionais />
        </Container>
      </div>
    </div>

    <Dicas />

    <div className='full-width' id='sobre-nos' style={{ position: 'relative' }} tabIndex={0}>
      <div className='' style={{ background: 'url("/apoio/v3/sobre-nos.jpeg")', backgroundPosition: 'center', width: '100%', backgroundSize: 'cover', position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, opacity: 0.4 }}></div>




      <Container className='gap-bg row-to-column fadeIn max-1200' style={{ minHeight: '80vh', paddingTop: '400px', background: 'none', zIndex: 900 }}>

        <div className='full-width max-1000'>

          <h2 className='hugeTitle2 colorWhite max-800' style={{ textAlign: 'left' }}>
            SOBRE NÓS
          </h2>

          <p className='contentParagraphSimple' style={{ textAlign: 'left', margin: '15px 0px' }}>
            Somos uma cooperativa do Rio Grande do Sul que investe nas pessoas, tecnologia, natureza e no cuidado e bem-estar dos animais para oferecer produtos mais saudáveis, ricos em sabor, nutrientes e amor. Aqui, nós acreditamos que para construir um mundo melhor é preciso ser feliz. Seres humanos e animais fazem parte da natureza e precisam viver em harmonia. E, para isso, nos unimos a pessoas, inspirados pelo cooperativismo, em um modelo justo e sustentável, no qual cada produtor é dono do negócio e, juntos, geram melhores resultados para todos. Produzir alimentos é a nossa vocação e, quando você leva nossos produtos para casa, além de qualidade, está levando um pouco das histórias de carinho, dedicação e respeito de toda família CCGL.
          </p>


        </div>




      </Container>
    </div>
  </>
  );
};
export default Home2;
