import React, { useEffect, useState, useRef } from 'react';
import { FaBirthdayCake, FaImage, FaPlayCircle } from 'react-icons/fa';
import Slider from 'react-slick';
import IframePlayerMobile from '../../../../components/IframePlayerMobile';
import InstagramFeed from '../../../../components/InstagramFeed';
import { urlWebsite, hash } from '../../../../config';
import { useModal } from '../../../../hooks/Modal';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { Container } from '../../styles';

const ReceitasContent: React.FC = () => {
    const { addModal } = useModal();
    const [receitasList, setReceitaList] = useState<Array<Record<string, any>>>([]);
    const sliderRef = useRef<Slider>(null);

    const loadReceitas = async () => {
        try {
            const receitas = await loadFromJson({ hash, project_id: 'leite-proteico', table: 'CientificLibrary' });

            if (receitas) {
                setReceitaList(receitas);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadReceitas();
    }, []);

    const list = receitasList?.reduce((prev: Array<JSX.Element>, item: Record<string, any>) => {
        prev.push(
            <div className='column gap-sm max-300-computer cursor' tabIndex={0}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        addModal({
                            title: '', theme: 'blackModal ', content: <div className='full-width row-to-column-reverse gap' style={{ alignItems: 'stretch' }}>
                                <div className='column max-400' style={{ alignSelf: 'flex-start' }}>
                                    <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                        <strong className='color1'>{item?.title}</strong>
                                    </p>
                                    <p className='contentParagraphSimple text-left  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}
                                        dangerouslySetInnerHTML={{ __html: item?.description || '' }} />
                                </div>
                                {item?.videoUrl ? <InstagramFeed list={[item?.videoUrl]} /> : <></>}
                            </div>
                        });
                    }
                }}
                onClick={() => {
                    addModal({
                        title: '', theme: 'blackModal ', content: <div className='full-width row-to-column-reverse gap' style={{ alignItems: 'stretch' }}>
                            <div className='column max-400' style={{ alignSelf: 'flex-start' }}>
                                <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                    <strong className='color1'>{item?.title}</strong>
                                </p>
                                <p className='contentParagraphSimple text-left  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}
                                    dangerouslySetInnerHTML={{ __html: item?.description || '' }} />
                            </div>
                            <InstagramFeed list={[item?.video_url]} />
                        </div>
                    });
                }}
                style={{ alignItems: 'stretch', margin: '0px auto' }}
            >
                <div className='column max-400  scaleOnHover'>
                    <nav className='contentParagraphSimple  max-1200 pad-sm row' style={{ background: '#fff', justifyContent: 'space-between' }}>
                        <FaPlayCircle style={{ color: '#333' }} />
                        <strong className='color1' style={{ fontSize: '14px' }}>{item?.title}</strong>
                    </nav>
                    <div className='playerDiv' style={{ position: 'relative' }}>
                        <div className='playContent'>
                            <FaPlayCircle />
                        </div>
                        <img alt={item?.title} src={`https://grupoccgl.encontrodigital.com.br/${item?.image}`} />
                    </div>
                </div>
            </div>
        );
        return prev;
    }, [] as Array<JSX.Element>);

    return (
        <Container tabIndex={0} id='receitas' className=' column ' style={{ minHeight: '30vh', padding: '100px 50px', background: 'none', zIndex: 900 }}>
            <h2 className='hugeTitle2  text-center  colorWhite max-800 pad'>
                Confira mais receitas deliciosas feitas com o leite em pó com alto teor de proteínas.
            </h2>
            <br /><br />
            <div className='full-width max-2000 gap row-to-column'>
                <div className=' max-1000 ' style={{ width: '100%' }}>
                    <Slider
                        ref={sliderRef}
                        autoplay
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                        slidesToShow={3}
                        slidesToScroll={1}
                        infinite
                        arrows={false}
                        dots={true}
                        appendDots={(dots: any) => (
                            <div className='row full-width gap-sm'>
                                {dots?.map((dot: any, index: number) => (
                                    <div
                                        key={index}
                                        className={`slick-dot ${dot?.props?.className}`}
                                        onClick={() => {
                                            if (sliderRef.current) {
                                                sliderRef.current.slickGoTo(index);
                                            }
                                        }}
                                    ><p>{index + 1}</p></div>
                                ))}
                            </div>
                        )}
                    >
                        {list}
                    </Slider>
                </div>
            </div>
        </Container>
    );
}

export default ReceitasContent;