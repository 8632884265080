import React from 'react';
import { useModal } from '../../../../hooks/Modal';

const CafeDaManha = () => {
    const { addModal } = useModal();

    return [<div className='column gap-sm max-400 cursor' tabIndex={0}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >SMOOTHIE DE BANANA, MAMÃO E AVEIA
                                    COM LEITE EM PÓ PROTEICO</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>

                                <strong>E QUE TAL UM CAFÉ DA MANHÃ RÁPIDO, PRÁTICO E COMPLETO PARA AQUELES DIAS MAIS CORRIDOS?
                                </strong>
                                <p>Isso é possível com um delicioso smoothie! Basta adicionar mamão e banana (carboidratos, antioxidantes e vitaminas) + farelo de aveia (fibras prebióticas) + novo leite em pó CCGL (proteínas) no liquidificador, bater todos os ingredientes com água e prontinho!
                                </p>  </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2'>ARTESANAL</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >COMFORT FOOD</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >LOW CARB</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}

        onClick={() => {


            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                            <strong className='color1' >SMOOTHIE DE BANANA, MAMÃO E AVEIA
                                COM LEITE EM PÓ PROTEICO</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>

                            <strong>E QUE TAL UM CAFÉ DA MANHÃ RÁPIDO, PRÁTICO E COMPLETO PARA AQUELES DIAS MAIS CORRIDOS?
                            </strong>
                            <p>Isso é possível com um delicioso smoothie! Basta adicionar mamão e banana (carboidratos, antioxidantes e vitaminas) + farelo de aveia (fibras prebióticas) + novo leite em pó CCGL (proteínas) no liquidificador, bater todos os ingredientes com água e prontinho!
                            </p>  </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2'>ARTESANAL</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2' >COMFORT FOOD</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2' >LOW CARB</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                            <strong className='color2' >+ PROTEÍNAS</strong>
                        </p>
                    </div>


                </>
            })


        }}>

        <div className='column max-400  scaleOnHover'>

            <img alt='Copo cheio com vitamina de mamão sobre a mesa de madeira decorada com bananas, mamão cortados em rodelas, aveia e gojiberry.' src="/apoio/v3/smoth.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                <strong className='color1' >SMOOTHIE DE BANANA, MAMÃO E AVEIA
                    COM LEITE EM PÓ PROTEICO</strong>
            </p>

        </div>

    </div>,

    <div className='column gap-sm max-400 cursor' tabIndex={0}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                                <strong className='color1' >LEITE COM MAIS PROTEÍNA SEM
                                    MUDAR A SUA ROTINA</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>

                                <p>  No último ano, novas categorias de alimentos passaram a fazer parte da mesa dos brasileiros, entre elas <strong>LEITE EM PÓ (+19%) e PÃO ARTESANAL (+62%). </strong>
                                </p>
                                <p>
                                    Então por que não adicionar uma dose de proteína extra ao seu café da manhã, mas na sua combinação habitual? Experimente com café, achocolatado ou até especiarias, como canela, pimenta, cúrcuma
                                </p>
                            </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>+ PROTEÍNA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ CÁLCIO</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ VERSATILIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >SEM GLÚTEN</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}
        onClick={() => {


            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                            <strong className='color1' >LEITE COM MAIS PROTEÍNA SEM
                                MUDAR A SUA ROTINA</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>

                            <p>  No último ano, novas categorias de alimentos passaram a fazer parte da mesa dos brasileiros, entre elas <strong>LEITE EM PÓ (+19%) e PÃO ARTESANAL (+62%). </strong>
                            </p>
                            <p>
                                Então por que não adicionar uma dose de proteína extra ao seu café da manhã, mas na sua combinação habitual? Experimente com café, achocolatado ou até especiarias, como canela, pimenta, cúrcuma
                            </p>
                        </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2'>+ PROTEÍNA</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ CÁLCIO</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ VERSATILIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                            <strong className='color2' >SEM GLÚTEN</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Dois copos com vitamina de cacau sobre a mesa de madeira, decorada com toalha xadrez, cookies e pedaços de chocolate.' src="/apoio/cafe2-pronto.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                <strong className='color1' >LEITE COM MAIS PROTEÍNA SEM
                    MUDAR A SUA ROTINA</strong>
            </p>


        </div>

    </div>,


    <div className='column gap-sm max-400 cursor' tabIndex={0}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >LEITE COM FRUTAS</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                <p> Que tal adicionar uma dose de proteína na fruta do seu café da manhã? O leite combina muito bem com as frutas, como por exemplo  banana, mamão e salada de frutas.
                                </p>
                                <p>
                                    Basta adicionar 30 gramas do <strong>NOVO LEITE EM PÓ CCGL</strong> à fruta de sua preferência e pronto! São 12 gramas a mais de proteínas para o seu café da manhã. </p>


                            </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>+ PROTEÍNA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ FRUTAS</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ PRATICIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >+ FIBRAS</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}
        onClick={() => {


            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                            <strong className='color1' >LEITE COM FRUTAS</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                            <p> Que tal adicionar uma dose de proteína na fruta do seu café da manhã? O leite combina muito bem com as frutas, como por exemplo  banana, mamão e salada de frutas.
                            </p>
                            <p>
                                Basta adicionar 30 gramas do <strong>NOVO LEITE EM PÓ CCGL</strong> à fruta de sua preferência e pronto! São 12 gramas a mais de proteínas para o seu café da manhã. </p>


                        </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2'>+ PROTEÍNA</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ FRUTAS</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ PRATICIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                            <strong className='color2' >+ FIBRAS</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Dois vidros de tampa hermética cheios de iogurte com leite e frutas sobre a mesa decorada com vasos de flores de lavanda.' src="/apoio/v3/leitecomfrutas2.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                <strong className='color1' >LEITE COM FRUTAS</strong>
            </p>



        </div>

    </div>]



}


export default CafeDaManha;