import React, { useState, createContext, useContext, useCallback, useEffect } from 'react';
import { uuid } from 'uuidv4';
import ModalContainer from '../components/ModalContainer';

export interface IModalMessage {
  id: string;
  title: string;
  content: JSX.Element;
  key?: string;
  theme?: string;
  closeButton?: string
}

interface IModalContextData {
  addModal(message: Omit<IModalMessage, 'id'>): void;
  removeModal(id: string): void;
  prepareKey(): string;
  messages: IModalMessage[];
}

const ModalContext = createContext<IModalContextData>({} as IModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IModalMessage[]>([]);
  const [className, setClassName] = useState('');
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Verifica se a tecla pressionada é Esc
      if (event.key === 'Escape') {
        messages?.map(message => {

          removeModal(message?.id || '');
        })

      }
    };

    // Adiciona o ouvinte de eventos ao document
    document.addEventListener('keydown', handleKeyDown);

    // Função de limpeza que remove o ouvinte de eventos quando o componente é desmontado
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [messages]);
  const prepareKey = useCallback(() => uuid(), []);

  const addModal = useCallback(
    ({ title, content, key, theme = '', closeButton = 'yes' }: Omit<IModalMessage, 'id'>) => {
      const id = key || uuid();
      const newMessage = {
        id,
        title,
        content,
        closeButton: closeButton
      };

      setClassName(state => theme);
      setMessages(state => [...state, newMessage]);

      return id;
    },
    [],
  );
  const removeModal = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, messages, removeModal, prepareKey }}>
      {children}
      <ModalContainer messages={messages} className={className} />
    </ModalContext.Provider>
  );
};

function useModal(): IModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { useModal, ModalProvider };
