import React from 'react';

const Proteina = () => {


    return [<div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200 pad-xs background1 displayReceitasTitle'>
                <strong className='colorWhite background1' >VITAMINA D</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '16px', background: '#fff', alignItems: 'stretch' }}>
                A vitamina D possui um papel crucial para manter os ossos fortes e saudáveis.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Absorção de cálcio</strong> - A vitamina D ajuda o corpo a absorver o cálcio dos alimentos. E o cálcio é um mineral essencial para construir e manter a força dos ossos.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Minimiza a perda óssea</strong> - A vitamina D também ajuda a reduzir a perda de cálcio pelos ossos. Isso é importante porque, ao longo do tempo, a perda excessiva de cálcio pode enfraquecer os ossos, tornando-os mais propensos a fraturas.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Previne doenças ósseas</strong> - A falta de vitamina D pode levar a problemas nos ossos, como o raquitismo em crianças e a osteoporose em adultos.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Desenvolvimento ósseo</strong> - Durante a infância e adolescência, a vitamina D é ainda mais crucial, pois ajuda no desenvolvimento ósseo adequado. Crianças que não recebem vitamina D suficiente podem ter problemas no crescimento e desenvolvimento dos ossos.

            </p>
        </div>

    </div>,

    <div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200  pad-xs background1 displayReceitasTitle'>
                <strong className='colorWhite background1' >CÁLCIO</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '14px', background: '#fff' }}>
                O cálcio é um mineral FUNDAMENTAL para a saúde óssea.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Material de construção</strong> - Os ossos são como a estrutura de uma casa e o cálcio é o material principal para construir e manter essa estrutura forte.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Prevenção de fragilidade</strong> - Quando você tem cálcio suficiente, seus ossos ficam mais fortes e “duros” e menos propensos a fraturas.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Prevenção de doenças ósseas</strong> - Ter cálcio suficiente ao longo da vida ajuda a prevenir problemas nos ossos, como a osteoporose, que torna os ossos frágeis e mais propensos a fraturas.
            </p>
        </div>

    </div>,

    <div className='column gap-sm max-400'>

        <div className='column max-400'>


            <p className='contentParagraphSimple  max-1200  pad-xs background1  displayReceitasTitle'>
                <strong className='colorWhite background1' >MAGNÉSIO</strong>
            </p>


            <p className='contentParagraphSimple color1  max-1200  column gap-sm pad displayReceitasBody' style={{ fontSize: '14px', background: '#fff' }}>
                Absorção de cálcio - O magnésio ajuda o corpo a absorver o cálcio, que é o principal componente dos ossos.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Estrutura óssea</strong> - O magnésio está envolvido na formação e manutenção da estrutura óssea. Ele contribui para a resistência e durabilidade dos ossos.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Previne fragilidade</strong> - Ter magnésio suficiente pode ajudar a prevenir a fragilidade dos ossos, tornando-os menos propensos a quebrar.

                <strong className='color1' style={{ textAlign: 'left', width: '100%' }}>Saúde geral dos ossos</strong> - Além de ajudar na absorção de cálcio, o magnésio desempenha um papel importante em várias reações que mantêm os ossos saudáveis e funcionando corretamente.    </p>
        </div>

    </div>

    ]



}


export default Proteina;