import React, { useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';

import Particles from 'react-particles-js';
import { Link } from 'react-router-dom';

import RodapeLP from '../Home/modules/RodapeLP';
import { Container } from '../Home/styles';

const FooterLayout: React.FC = ({ children }) => {
  return (<><div style={{ zIndex: -1 }}>
    <Particles
      params={{
        particles: {
          lineLinked: {
            enable: false,
            distance: 250
          },
          move: {
            speed: 15,
            direction: window?.location?.pathname?.indexOf('/podcasts') >= 0 ? 'bottom' : 'top-right',
            collisions: false,
            outModes: {
              default: 'out',
              left: 'out',
              right: 'out',
              bottom: 'out',
              top: 'out',
            },


          },
          opacity: {
            value: 0.4,
            random: false,
            animation: {
              enable: false
            }

          },
          shape: {
            type: 'circle',
            polygon: {

              nb_sides: 6,

            },

          },

          color: { value: window?.location?.pathname?.indexOf('/podcasts') >= 0 ? '#f25930' : 'rgb(171, 230, 255)' },

          number: {
            density: {
              enable: true,
              value_area: 2400,
            },
            value: 60,
          },
          size: {
            value: window?.location?.pathname?.indexOf('/podcasts') >= 0 ? 10 : 2,
            random: true,
          },
        },
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: -1
      }}
    />

  </div>
    <Container className='gap-bg column ' style={{ minHeight: '20vh', padding: '100px 15px', background: '#efe8df', zIndex: 9000000 }}>

      <div className='max-1000 gap-sm column'>
        <div className='full-width column'>
          <p className='contentParagraph  blackColor footerTitle max-1200'>
            <strong>Quer acompanhar essa e as próximas novidades?</strong>
          </p>
          <p className=' blackColor max-1200 footerParagraph'>
            Siga a CCGL nas redes sociais e não perca nada!
          </p>
        </div>
        <div style={{ background: '#333', margin: '15px auto' }} className='separadorLp' />


        <div className='full-width row row-to-column gap-bg flex-wrap'>

          <a href="https://www.instagram.com/ccgloficial?igsh=MWN3bzJzYzVpNGc4eg==" target='_BLANK' className='row row-start max-300  min-200 gap-sm ' style={{ width: 'auto' }}>
            <img loading='lazy' src="/apoio/pro/instagram.png" className='socialImage' />
            <p className='contentParagraphSimple socialTitle black'>SEGUIR AGORA<br />
              MESMO O INSTAGRAM</p>
          </a>


          <a href='https://www.facebook.com/grupoccgl?mibextid=ZbWKwL' target='_BLANK' className='row row-start max-300  min-200 gap-sm ' style={{ width: 'auto' }}>
            <img loading='lazy' className='socialImage' src="/apoio/pro/facebook.png" />
            <p className='contentParagraphSimple socialTitle black'>ACOMPANHAR TUDO<br />
              NO FACEBOOK</p>
          </a>


          <a href="https://loja.ccgl.com.br/produto/pa000085v03pl-un/leite-em-po-desnatado-vitaminado-com-alto-teor-de-proteinas-270g" target='_BLANK' className='row row-start max-300 min-200 gap-sm ' style={{ width: 'auto' }}>

            <div className='socialImage' style={{ borderRadius: '50%', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaShoppingCart style={{ fontSize: '35px', color: '#fff' }} />
            </div>
            <p className='contentParagraphSimple socialTitle black'>VISITE<br />
              NOSSA LOJA</p>
          </a>
        </div>


        {/*   <div className='full-width row  gap-sm ' style={{ flexWrap: 'wrap', marginTop: '50px' }}>


         
          <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/">Home</Link>
          <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/nutrientes">Nutrientes</Link>
          <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/dicas">Dicas</Link>
         <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/como-e-feito">Como é feito</Link> 
          <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/massa-muscular">Massa Muscular</Link>
          <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/desempenho-maximo">Desempenho máximo</Link>
          <Link onClick={() => window?.scrollTo(0, 0)} style={{ display: 'inline-block', width: 'auto' }} className='socialTitle black cursor' to="/vitalidade">Vitalidade</Link>


        </div>*/}


      </div>


    </Container>
    <div className='full-width' style={{ background: '#efe8df' }}>
      <div className='full-width row row-to-column gap pad' style={{ alignItems: 'center', background: 'rgba(255,255,255,0.3)' }}>

        <div className='full-width max-500 column column-start gap-xs'>

          <a href='https://app.ccgl.com.br/utils/politicas/site/politica.html' target='_BLANK' title='POLÍTICA DE PRIVACIDADE E TERMOS DE USO'><p className='color5 scaleOnHover'>POLÍTICA DE PRIVACIDADE E TERMOS DE USO</p></a>
          <a href='https://app.ccgl.com.br/utils/politicas/politica_de_cookies.html' title='POLÍTICA DE COOKIES' target='_BLANK'><p className='color5 scaleOnHover'>POLÍTICA DE COOKIES</p></a>
          {/*    <a href='https://app.ccgl.com.br/utils/politicas/politica_de_qualidade.html' title='POLÍTICA DE QUALIDADE E SEGURANÇA DE ALIMENTOS' target='_BLANK'><p className='color5 scaleOnHover'>POLÍTICA DE QUALIDADE E SEGURANÇA DE ALIMENTOS</p></a> */}
          <a href='https://app.ccgl.com.br/site/contato' target='_BLANK' title='CONTATO'><p className='color5 scaleOnHover'>CONTATO</p></a>

        </div>
        <div>
          <img className='max-200' src="/apoio/v3/somoscoop.png" />
        </div>

      </div>
    </div>

    <div className='full-width row row-to-column gap pad' style={{ alignItems: 'flex-start', background: '#efe8df' }}>

      <div className='full-width max-300 column column-start'>
        <p className='color5 full-width' style={{ fontWeight: 'bold' }}>ESCRITÓRIO COMERCIAL</p>
        <p className='full-width color5'>R MARQUÊS DO POMBAL | Nº 1854<br />
          HIGIENÓPOLIS | CEP 90540-000<br />
          PORTO ALEGRE | RS | BRASIL<br />
          comercial@ccgl.com.br | (51) 3337.7256
        </p>
      </div>
      <div>
        <p className='color5 full-width' style={{ fontWeight: 'bold' }}>UNIDADE DE LATICÍNIOS</p>
        <p className='full-width color5'>Rodovia RS 342, KM 149, S/N - CEP 98.015-562<br />
          Caixa postal 10 - Santa Terezinha - Cruz Alta - RS - Brasil<br />
          ccgl@ccgl.com.br - (55) 3321.9400
        </p>
      </div>

    </div>

    <p style={{ fontSize: '16px', padding: '30px', paddingBottom: '100px', textAlign: 'center', color: '#fff', width: '100%' }}>® 2024 CCGL - Leite de Verdade | Todos os direitos reservados</p>

  </>
  );
};

export default FooterLayout;
