import React from 'react';
import { useModal } from '../../../../hooks/Modal';

const AlmocoEJantar = () => {
    const { addModal } = useModal();

    return [<div className='column gap-sm max-400 cursor' tabIndex={0}

        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >PURÊS PROTEÍCOS</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                A combinação de arroz e feijão está presente na maioria das ocasiões de consumo dos brasileiros nessa refeição. E que tal um acompanhamento para esse clássico?
                                <br /><br />
                                Esses acompanhamentos são fonte de carboidratos, mas podem ter uma dose extra de proteína! Para isso, basta adicionar o novo leite em pó CCGL as suas receitas de purês e cremes, como os clássicos purê de batata ou creme de milho.

                            </p>
                        </div>

                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2'>CONFORT FOOD</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >VERSATILIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                                <strong className='color2' >LOW CARB</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}


        onClick={() => {
            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                            <strong className='color1' >PURÊS PROTEÍCOS</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                            A combinação de arroz e feijão está presente na maioria das ocasiões de consumo dos brasileiros nessa refeição. E que tal um acompanhamento para esse clássico?
                            <br /><br />
                            Esses acompanhamentos são fonte de carboidratos, mas podem ter uma dose extra de proteína! Para isso, basta adicionar o novo leite em pó CCGL as suas receitas de purês e cremes, como os clássicos purê de batata ou creme de milho.

                        </p>
                    </div>

                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2'>CONFORT FOOD</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2' >VERSATILIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }}>
                            <strong className='color2' >LOW CARB</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag  background1 color2 ' style={{ width: "calc(50% - 15px)", fontSize: '16px' }} >
                            <strong className='color2' >+ PROTEÍNAS</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Prato de louça sobre a mesa com purê de batatas salpicado com cebolinha. Mesa decorada com batatinhas, e utensílios de cozinha.' src="/apoio/almoco1.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                <strong className='color1' >PURÊS PROTEÍCOS</strong>
            </p>


        </div>

    </div>,

    <div className='column gap-sm max-400 cursor' tabIndex={0}

        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>

                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                                <strong className='color1' >TORTA PROTEICA CREMOSA</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>
                                Torta é uma refeição gostosa e prática, sendo uma opção para o almoço e jantar.
                                <br /><br />
                                Por fazer o papel principal na refeição é importante que a torta tenha proteínas, que podem estar adicionadas no recheio (frango, carne, atum etc.) ou na massa! Para isso, basta adicionar o novo leite em pó CCGL a sua massa clássica de torta cremosa! </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>PRATICIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >VARIEDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >COMFORT FOOD</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })
            }
        }}
        onClick={() => {
            addModal({
                title: '', theme: 'blackModalMedium ', content: <>

                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                            <strong className='color1' >TORTA PROTEICA CREMOSA</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody ' style={{ fontSize: '14px' }}>
                            Torta é uma refeição gostosa e prática, sendo uma opção para o almoço e jantar.
                            <br /><br />
                            Por fazer o papel principal na refeição é importante que a torta tenha proteínas, que podem estar adicionadas no recheio (frango, carne, atum etc.) ou na massa! Para isso, basta adicionar o novo leite em pó CCGL a sua massa clássica de torta cremosa! </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2'>PRATICIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >VARIEDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >COMFORT FOOD</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                            <strong className='color2' >+ PROTEÍNAS</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Zoom focado em um prato de louça branco, com uma fatia de torta de legumes. Ao fundo imagem o refratário da torta desfocado.' src="/apoio/almoco2.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>

                <strong className='color1' >TORTA PROTEICA CREMOSA</strong>
            </p>


        </div>

    </div>,


    <div className='column gap-sm max-400 cursor' tabIndex={0}

        onKeyDown={(event) => {
            if (event.key === 'Enter') {

                addModal({
                    title: '', theme: 'blackModalMedium ', content: <>
                        <div className='column max-400'>


                            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                                <strong className='color1' >WRAP PROTEICO + ACOMPANHAMENTOS DE PREFERÊNCIA</strong>
                            </p>


                            <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                                As panquecas são famosas pela sua versatilidade, podendo ser base para um alimento doce ou salgado!
                                <br /><br />
                                E que tal adicionar proteína a essa massa? Para isso, basta substituir o leite fluído da panqueca por leite em pó CCGL (já preparado), adicionar o resto dos ingredientes de sua preferência (farinha, óleo, sal, ovo) e pronto!
                            </p>
                        </div>
                        <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2'>PREPARAÇÃO RÁPIDA</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >VERSATILIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                                <strong className='color2' >+ PRATICIDADE</strong>
                            </p>
                            <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                                <strong className='color2' >+ PROTEÍNAS</strong>
                            </p>
                        </div>


                    </>
                })

            }

        }}


        onClick={() => {
            addModal({
                title: '', theme: 'blackModalMedium ', content: <>
                    <div className='column max-400'>


                        <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                            <strong className='color1' >WRAP PROTEICO + ACOMPANHAMENTOS DE PREFERÊNCIA</strong>
                        </p>


                        <p className='contentParagraphSimple  max-1200 background1 column gap-sm pad displayReceitasBody' style={{ fontSize: '14px' }}>
                            As panquecas são famosas pela sua versatilidade, podendo ser base para um alimento doce ou salgado!
                            <br /><br />
                            E que tal adicionar proteína a essa massa? Para isso, basta substituir o leite fluído da panqueca por leite em pó CCGL (já preparado), adicionar o resto dos ingredientes de sua preferência (farinha, óleo, sal, ovo) e pronto!
                        </p>
                    </div>
                    <div className='dashedBorder row pad-sm gap-sm max-400' style={{ flexWrap: 'wrap', justifyContent: 'stretch' }}>

                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2'>PREPARAÇÃO RÁPIDA</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >VERSATILIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }}>
                            <strong className='color2' >+ PRATICIDADE</strong>
                        </p>
                        <p className='contentParagraphSimple keywordReceitasTag background1 color2' style={{ width: "calc(50% - 15px)", fontSize: '18px' }} >
                            <strong className='color2' >+ PROTEÍNAS</strong>
                        </p>
                    </div>


                </>
            })


        }}

    >

        <div className='column max-400  scaleOnHover'>

            <img alt='Wrap cortado ao meio mostrando a abundância de recheio, envolvido em papel manteiga e amarrado com uma delicada linha vermelha.' src="/apoio/almoco3.jpeg" />
            <p className='contentParagraphSimple  max-1200 pad-sm background2 displayReceitasTitle'>
                <strong className='color1' >WRAP PROTEICO + ACOMPANHAMENTOS DE PREFERÊNCIA</strong>
            </p>


        </div>

    </div>]



}


export default AlmocoEJantar;