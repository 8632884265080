
import React, { useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
interface IProps {
    list: Array<string>
}

const InstagramFeed: React.FC<IProps> = ({ list }) => {

    const [activeIndex, setActiveIndex] = useState(0);


    return <div className='full-width column' >
        <InstagramEmbed key={`${activeIndex}-embededInstagram`} url={list[activeIndex]} width={328} />


        <div className='row gap-sm'>
            {list?.length > 1 ? list?.map((item, index) => {

                return <div onClick={() => setActiveIndex(index)} style={{ display: 'flex', borderRadius: '5px', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', background: activeIndex === index ? '#4f659c' : '#fff', color: activeIndex === index ? "#fff" : '#4f659c', fontSize: '16px', width: '30px', height: '30px' }}>
                    0{index + 1}
                </div>

            }) : <></>}
        </div>
    </div>


}

export default InstagramFeed;