import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,

} from './styles';



import { useAuth } from '../../hooks/Auth';

import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';

import api from '../../services/api';
import { hash, urlWebsite } from '../../config';

import { loadFromJson } from '../../utils/loadFromJson';
import LeiteEmPo from '../ComoEFeito/modules/LeiteEmPo';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Muscular: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const data = [

    { img_style: { width: '70px', margin: '0px 15px' }, icon: '/apoio/pro/energia.png', title: 'ENERGIA PARA SUA VIDA', description: 'Cada porção do leite em pó CCGL é enriquecida com nutrientes (magnésio, vitamina B1, vitamina B3, vitamina B5) que estão relacionados ao metabolismo de energia. Ou seja, ajudam a manter o funcionamento normal do seu metabolismo de energia, acompanhando seu ritmo acelerado!' },
    { img_style: { width: '90px', margin: '0px 5px' }, icon: '/apoio/flexibilidade.png', title: 'FLEXIBILIDADE NA ROTINA', description: 'A sua vida é uma jornada movimentada e sabemos que sua nutrição deve ser tão adaptável quanto você. Nosso leite em pó é a solução prática e versátil para suas necessidades nutricionais, seja em casa, na academia ou no trânsito. Apenas um shake, misturando o leite em pó com água, e você está pronto para conquistar o dia.' },
    { img_style: { width: '90px', margin: '0px 5px' }, icon: '/apoio/pro/recuperacao.png', title: 'RECUPERAÇÃO RÁPIDA PARA O SEU DIA A DIA', description: 'Sabemos que a recuperação é crucial para sustentar uma vida ativa. Com uma mistura equilibrada de proteínas de qualidade e outros nutrientes, nosso leite em pó auxilia na recuperação muscular, garantindo que você esteja pronto para enfrentar cada desafio, mantendo o equilíbrio entre trabalho, diversão, esporte e bem-estar.' },
    { img_style: { width: '80px', margin: '0px 10px' }, icon: '/apoio/nutrido.png', title: 'MANTENHA-SE NUTRIDO, MESMO EM MOVIMENTO', description: 'Sabemos que a recuperação é crucial para sustentar uma vida ativa. Com uma mistura equilibrada de proteínas de qualidade e outros nutrientes, nosso leite em pó auxilia na recuperação muscular, garantindo que você esteja pronto para enfrentar cada desafio, mantendo o equilíbrio entre trabalho, diversão, esporte e bem-estar.' },
    { img_style: { width: '70px', margin: '0px 15px' }, icon: '/apoio/multitarefa.png', title: 'PARA OS MULTITAREFADOS E OS ACELERADOS', description: 'Seja você um multitarefa profissional, um entusiasta do fitness com uma agenda lotada ou alguém que simplesmente abraça o caos controlado da vida, O LEITE EM PÓ PROTEICO CCGL ESTÁ AQUI PARA SER O APOIO ESSENCIAL NO SEU DIA A DIA.' },

  ]
  const currentData = data?.[currentIndex] || {};

  return (<>
    <img src="/apoio/cabecalho-leite.png" style={{ width: '100%', zIndex: 10000 }} />


    <Container className='gap-bg column  ' style={{ minHeight: '80vh', background: 'none', zIndex: 900 }}>
      <div className='row full-width fadeIn '>  <LeiteEmPo style={{ textAlign: 'center' }} />
      </div>
      <div className='max-1000 gap-bg column fadeIn '>
        <img loading='lazy' src='/apoio/pro/imagens-pagina4.png' className='max-1000 computerOnly' />

        <img loading='lazy' src='/apoio/pro/pag4-mobile.png' className='max-1000 mobileOnly' />


        <p className='contentParagraphSimple fadeIn  column max-1000 gap-sm'>
          <strong className='background2 pad-sm color1' style={{ width: '100%' }}  >ENERGIZE SEU ESTILO DE VIDA ATIVO: LEITE EM PÓ PROTEICO PARA QUEM NÃO PARA!</strong>
          <p className=' pad-sm' style={{ textAlign: 'left' }}> Caro Amante de um Estilo de Vida Ativo, Se a sua vida é uma constante dança entre os compromissos diários, o trabalho, os treinos e a agitação constante, temos a solução ideal para manter sua energia em alta e nutrir seu corpo nos momentos mais movimentados. Apresentamos o nosso Leite em Pó Proteico  CCGL - a resposta para você, que não para!</p></p>


        <div className='column computerOnlyFlex'>

          {data?.map(item => {
            return <div className='row fadeIn   row-start row-to-column max-1000 gap-md pad-sm'>
              <img loading='lazy' className='max-100' style={{ ...item?.img_style }} src={item?.icon} />
              <p className='contentParagraphSimple text-left max-1000'>
                <strong className='color2'>{item?.title}</strong> <br />
                <p  > {item?.description}</p></p>
            </div>


          })}

        </div>

        <div className='mobileOnlyFlex column'>

          <div className='row gap-xs'>

            {data?.map((item, index) => {

              return <div style={{ borderRadius: '7.5px', width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={`pad-xs fadeIn  ${index === currentIndex ? 'background1 ' : 'none'}`}>
                <img loading='lazy' onClick={() => setCurrentIndex(index)} style={{ maxHeight: '50px', width: 'auto' }} src={item?.icon} /></div>
            })}


          </div>

          <div key={currentData?.title} className='row fadeIn  row-start rcolumn max-1000 gap-md pad-sm'>
            <p className='contentParagraphSimple text-left max-1000'>
              <strong className='color2'>{currentData?.title}</strong> <br />
              <p  > {currentData?.description}</p></p>
          </div>


        </div>


      </div>



    </Container>

  </>
  );
};
export default Muscular;
