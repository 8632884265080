import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';
import themeDefault from './ThemeComponents';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`
const scale = keyframes`
0% {
  transform: scale(1) translateY(0%);
  
}
50%{
  transform: scale(1.05) translateY(-50%);
}
100% {
  transform: scale(1) translateY(0%);
}
`

const milk2 = keyframes`
0% {
  transform: scale(1) translateX(00%);
  
}
50%{
  transform: scale(1.1) translateX(10%);
}
100% {
  transform: scale(1) translateX(00%);
}
`

const milk3 = keyframes`
0% {
  transform: scale(1) translateX(00%);
  
}
50%{
  transform: scale(1.1) translateX(-10%);
}
100% {
  transform: scale(1) translateX(00%);
}
`

const fadeIn = keyframes`
from {
  transform: translateX(-15px) ;
  opacity: 0 ;
}
to{
  transform: translateX(0px) ;
  opacity: 1 ;
`

export default createGlobalStyle`

@font-face {
    font-family: Gilroy-Regular;
    src: url("/assets/fonts/Gilroy-Regular.ttf") format("truetype");
}
 


@font-face {
    font-family: Gilroy-Bold;
    src: url("/assets/fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: GothamBook;
    src: url("/assets/fonts/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: GothamBlack;
    src: url("/assets/fonts/Gotham-Black.otf") format("truetype");
}

@font-face {
    font-family: GothamUltra;
    src: url("/assets/fonts/GothamUltra.otf") format('opentype');
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;


}

#privacy-widget{
  h3{ font-weight: 400 !important;}
table{
  border-collapse: collapse;
}

button{
  padding: .6em 1.2em;
  border-radius: 8px;
}

tr{
  border: 1px solid #ddd;

  td{
    padding: 10px;
    border: 1px solid #ddd;
    font-weight: 400;

    h3{
      font-weight: 400 !important;
    }
  }
}


}
.whiteModal { 
  p,span,h3, strong, h2, h1, h4, nav, aside {
    color: #333 !important;
  }
}

body {
 //background: url('/assets/Fundo.png') no-repeat center;
background-size: cover ;
 
 background : #242424;

 .fundoCapa{
  background : #242424;
  background: url('/assets/Fundo.jpeg') no-repeat center;
  background-size: cover ;
 
 }

 .sideBG{
   background-position: 50% 100% !important;
   background-size: cover  !important;
   background-repeat: no-repeat  !important;
 }

 .hugeTitleMargin{
   margin-left: 100px;
 }

.hugeTitle{
  font-size: 86px;
  text-align: left;
  max-width: 500px;

  @media (max-width: 1200px) {
    font-size: 36px;
    
  }
}

.hugeTitle2{
  font-size: 42px;
  text-align: right;

  @media (max-width: 1200px) {
    font-size: 22px;
    
  }
 
}
.hugeTitle3{
  font-size: 32px;
  text-align: right;

  @media (max-width: 1200px) {
    font-size: 22px;
    
  }
 
}


.cursor{
cursor: pointer ;
}
.scaleOnHover{

  transition: 0.5s all;

  &:hover{
    transform: scale(1.05) ;
  }

}


.midTitle2{
  font-size: 28px !important;
  @media (max-width: 1200px) {
    font-size: 18px !important;
    
  }
 
}

.midTitle3{
  font-size: 22px !important;
  @media (max-width: 1200px) {
    font-size: 18px !important;
    
  }
 
}

.midTitle4{
  font-size: 14px !important;
  @media (max-width: 1200px) {
    font-size: 14px !important;
    
  }
 
}

.midTitle4{
  font-size: 16px !important;
  @media (max-width: 1200px) {
    font-size: 16px !important;
    
  }
 
}

.animateMilk {
  animation: ${scale} 15s infinite;
}

.animateMilk2 {
  animation: ${milk2} 12s infinite;
}

.animateMilk3 {
  animation: ${milk3} 14s infinite;
}

.keywordReceitasTag{
  font-size: 12px !important;
  padding: 5px ;
  @media (max-width: 1200px) {
    font-size: 12px !important;
    
  }
}

.brandCall{
  display: flex ;
 
  flex-direction: column;
  align-items: flex-end;

 position: absolute ;
 right: 50px;
 bottom: 50px;

  img{
    width: 180px;
  }


  @media (max-width: 750px) {
    position: relative;
 right: auto;
 bottom: auto;

 width: 130px;
 img{
    width: 95px;
  }

  }
}

 .colorWhite{
  color: #fff !important;
 }

 .color1{
color: #404040 !important;
 }

 .background1{
background: #404040;
 }

 .blackBackground {
   background : rgba(0,0,0,0.6);
   transition: 0.3s all ;

   &:hover{
    background : rgba(0,50,150,0.3);
   }
 }

 .acessibilityMenu{
  nav {
    width: 100%;
 
    display: flex ;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #fff;
    margin-bottom: 10px;

    div{
      min-width: 40px;
      display: flex ;
    flex-direction: row;
    justify-content: center;
      svg {
        font-size: 32px;
      }
    }

    p{
      color: #fff;
      padding: 0px 16px;
    }
  }

 }

 .dashedBorder {
   border: 2px dashed #404040;
 }

 .dashedBorderWhite {
   border: 1px dashed #fff;

   &:hover{
    border: 1px dashed #fff;
    color: #fff !important;
   }
 }

 .color2{
   color: #f8cd18 !important;
 }
 .colorBlue{
   color: #5993ff !important;
 }
 

.background2{
  background: #f8cd18 !important;
}

.background3{
  background: #f3eed6 !important;
}

.background4{
  background: #1d4482 !important;
}


.background5{
  background: #ddd !important;
}


.color4{
  color: #1d4482 !important;
}

.color5{
  color: #333 !important;
}


 .color3{
   color: #191061 !important;
 }

 .flex {
   display: flex !important ;
 }

 .nutritionalTable{
   width: 100%;
   border-collapse: collapse;
   tr{
     border: 0px;

     th {
       font-weight: bold;
       padding: 5px 10px ;
     }

     td {
       padding: 5px 10px ;
     }
   }
 }


 .nutritionalTableMix{
   width: 100%;
   border-collapse: collapse;
   tr{
     border: 0px;

     th {
       font-weight: bold;
       padding: 5px 10px ;
     }

     td {
       padding: 5px 10px ;
     }
   }

   @media (max-width: 750px) {
  
    th,td {
       font-size: 12px;
     }
 

  }
   
 }

 .center{
   text-align:center !important;
 }

 .borderedTable{
   td{
     border: 2px solid #fff;
   }
 }

 .blueBall {
   width: 170px;
   border-radius: 50%;
   height: 170px;
   display: flex ;
   align-items: center;
   justify-content: center;
   color: #fff;
   background: #1d4482 ;
   padding: 10px;

   h2{
     color: #fff;
     text-align: center;
     font-size: 12px;
   }


   @media (max-width: 750px) {
  
 width: 100px;
 height: 100px;
 h2{
   font-size: 9px;
  }

  }
 }

 .displayReceitasTitle{
   display: flex ;
   align-items: center;
   justify-content: center;
   min-height: 80px;
 }

 .displayReceitasBody{

  padding: 30px;
  min-height: 300px;

  font-size: 14px !important;
  text-align: left !important;

 }
 
 .rotinaAlimentarTitle{
  @media (max-width: 1200px) {
    font-size: 16px !important; 
    
  }
 }

 .notificacaoMinisterio{
  font-size: 10px; 
  color: #fff;
  text-align: center; 
  padding: 15px; 
  border: 2px solid #fff; 
  margin: 15px 15px; 
margin-bottom: 100px;

  width: 80%;
 
 margin-left: 15%;
 

  strong{
    color: #fff;
  }

  @media (max-width: 650px) {
    padding: 5px; 
    font-size: 5px; 
    border: 1px solid #fff; 
    
  }
 }

>iframe {
  position: relative !important;
  display: none ;
}


.fadeIn{
  animation: ${fadeIn} 0.5s forwards ;
}

.contentParagraph{
  color: #fff;
  font-size: 28px;
  text-align: center;
  width: 100%;
 
  margin: 0px auto;



  @media (max-width: 650px) {
    font-size: 18px;
    
  }


  strong{
    font-weight: 400;
    color: #fff;
  }
}

.footerTitle{
  font-size: 36px;
  @media (max-width: 650px) {
    font-size: 26px;
    
  }
}

.footerParagraph{
  font-size: 30px;
  @media (max-width: 650px) {
    margin-top: 15px;
    font-size: 13px;
    text-align: center !important;
    width: 100%;
    
  }
}

.uppercase{
  text-transform: uppercase;
}

.separadorLp{
  height: 2px;
  background: #fff;
  width: 90%;
  max-width: 1000px;
  margin: 30px auto;
}

.blackColor{
  color: #333;
  strong{
    color: #333;
  }
}

.colorPrimary{
  color: #322f2e !important;
}

.contentParagraphSimple{
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: 100%;
 
  margin: 0px auto;
 
  @media (max-width: 650px) {
    font-size: 16px;
    
  }

  strong{
    font-weight: 400;
    color: #fff;
  }
}

.socialImage{
  width: 60px !important;
  height: 60px !important;
  min-width: 60px !important;
  min-height: 60px !important;
  @media (max-width: 650px) {
    width: 40px  !important;
    height: 40px !important;
    min-width: 40px  !important;
    min-height: 40px !important;

    svg{
      width: 25px;
      height: 25px;
    }
  }
}

.socialTitle{

  font-weight: bold;
  text-align: left;
  color: #333;

  font-size: 18px;

  @media (max-width: 650px) {
    font-size: 10px;
    
  }


}

.text-left{
  text-align: left !important;

  p,span,h2,h3,strong{
    text-align: left !important;
  }
}

.CaixaPesquisa{
  margin: 0px auto ;
  margin-top: -100px;
  width: 100%;
  display: flex ;
    align-items: center;
    justify-content: flex-start;
   
  >div{
    width: 100% ;
 padding: 5px 15px 5px 5px ;
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    background: #fff;

  input{
    background: #fff ;
    padding: 15px;
    width: 100%;
    max-width: 450px;
    border: none;
  }
  button{
    padding: 15px;
    width: 150px;
  }
}
}

.homePesquisa{
  @media (max-width: 750px) {
    margin-top: 10px;
    padding: 10px ;
    
  }
}

.p50.socialFooter .iconSet svg{
  
    min-width: 45px !important;
    font-size: 45px !important;
 
}
 
.typeButtonInitial2 {
      display: flex ;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: ${themeDefault.alternativeButton.color};
      background: ${themeDefault.alternativeButton.background};
      min-width: 100px;
      font-weight: bold;
      border : 0px;
      border-radius: 10px;
      margin: 5px;
      font-size: 12px;

    }


    .selectButtonMini{
      background: #f15a23 ;
      color: #fff;
      transition: 0.5s all;
      border: 0px;
      padding: 5px;
      font-size: 16px;
      border-radius: 5px;

      &:hover{
      background: rgb(0,14,64);
    }
    }

.searchButton{
    background: rgba(0,100,0);
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 30px;
      font-size: 30px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }

  .searchButtonMini{
    background: rgba(0,100,0);
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 20px;
      font-size: 20px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }
 


  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;



  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.tooltip{
  background: #777 ;
  color: #333;
}

.spin {
  animation: ${rotate} 2s infinite ;
}

.table{
  width: 100%;
  border-collapse: collapse;
  td{
    border: 1px solid #ddd;
    padding: 10px;
    color: #333;

  }
}
.menuBlack{
  font-family:'GothamBlack',Helvetica,  sans-serif !important;
  color: #fff !important;
}


.menuFont{
  font-family:'GothamBook',Helvetica,  sans-serif;
  color: #fff !important;
}


.searchDivStart2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        max-width: 1600px;
        padding: 0px 15px;
        margin: 0px auto;

        >select, input {
          min-width: 300px;
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;
          margin:  10px;
        }

        button{
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;

          >svg{
            font-size: 18px;
          }
        }

        @media (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
    select, input {
      width: 100%;

    }

    div{
      width: 100%;
    }
    
  }
      }

.slick-slider{
  width: 100%;

 
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  position: absolute ;
  top: 50%;
  left: 0px;
  z-index: 1;
  &:before{
    color: #00aaef;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  position: absolute ;
  top: 50%;
  right: 0px;
  z-index: 1;
  &:before{
    color:  #00aaef;
    font-size: 40px;
    opacity: 1 ;
  }
}

.fixedWhatsapp{
  position: fixed;
    bottom: 15px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    background: rgb(50,150,26);
    color: #fff !important;
    padding: 15px;
    border: 0px;
}


.buttonBigGreen{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 20px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
  margin-bottom: 20px;
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;

}

.buttonBigGreenIcon{

align-self: center;
background-color: #1c9284;
border-bottom-style: none;
border-color: #1c9284;
border-left-style: none;
border-radius: 10px;
border-right-style: none;
border-top-style: none;
border-width: 0px;
color: #FFFFFF;
cursor: pointer;
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: bold;
height: 50px;
justify-content: center;
line-height: 1.42857;

margin-top: 0px;
max-width: 388px;
text-align: center;
text-decoration: none;
width: 40px;
height: 40px;
padding-right: 10px;
padding-left: 10px;

gap: 10px;
display: flex ;
align-items: center;
justify-content: center;

>svg{
  font-size: 24px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

}


.CaptureLeads{
  min-width: 250px;
  background: rgb(241, 90, 35)  ;
  color: ${themeDefault.textComponent.title.color} ;
  padding: 10px 15px;
  border-radius: 5px;
  border: 0px;

  @media (max-width: 1000px) {
    display: none ;
  }

}

.divSecoundaryBackground {
  background: ${themeDefault?.textComponent?.title.color} !important;
}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'Gilroy-Bold',Helvetica,  sans-serif;
color : #8b8b8b;
}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Gilroy-Regular',Helvetica,  sans-serif;
  color : ${themeDefault.textComponent.paragraph.color};
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff ;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64) ;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 5px rgba(171, 230, 255,0.7);
       
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
      color: rgb(0, 15, 64)  !important;
      font-weight: bold;

      background: rgb(171, 230, 255) !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #333  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #333  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini,     button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64) !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }


    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      cursor: pointer;
      background: #f15a23 !important;
      color: #fff  !important;
      border-radius: 10px  !important;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
  border: 1px solid #ddd  !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2)  !important;

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}

.slick-list {margin: 0 -15px; 
  padding: 15px 0px;
}
.slick-slide>div {padding: 0 15px;}

.primary {}

.secondary{}

.titleH2{
  font-size: 32px;
    border-left: 2px solid #fcc010;
    padding: 10px 30px;
 font-weight: 400;
 color: #5b5b5f !important;
     font-family:'GothamUltra',Helvetica,  sans-serif;
     letter-spacing: 2.8px;
}

.container{
    width: 100%;
}

.width-full{
    width: 100%;
}

.width-auto{
    width: auto !important ;
}
.particles-wrapper {
 position :  absolute;
 top: -50px;
 left: 50px ;
 right: 0px;
 bottom: 0px;
  height: 600px;
  width: 100%; // You may need this or may not depending on your requirement
  z-index: -1;

  @media (max-width: 650px) {
    height: 400px;
  width: 90%;
  top: -300px;
    }
}

[tabindex="0"]&:focus {
   border: 2px dashed #ff7755; /* Azul sólido para o contorno */
   border-radius: 5px;
}

.playerDiv{
min-width:30%;
width: 100%;
  &:hover{

    .playContent {
    svg{
      opacity: 1 ;
      font-size: 54px;
    }
  }
  }

  .playContent{
    position: absolute ;
    top: 50%;
    left: 0px ;
    width: 100%;
    display: flex ;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg{
      margin-top: -30px;
      transition: 0.5s all;
      font-size: 64px;
      color: #fff;
      opacity: 0.5 ;
    }

   h2{
     
      color: #fff;
      font-size: 14px;
    }

  }
}
   
.min-100{
    min-width: 100px;
}


.min-200{
    min-width: 200px;
}
.min-300{
    min-width: 300px;
}
.min-400{
    min-width: 400px;
}

.min-600{
    min-width: 600px;
}
.max-50{
    max-width: 50px;
    @media (max-width: 650px) {
      max-width: 30px;
    }
}

.max-70{
    max-width: 70px;
    @media (max-width: 650px) {
      max-width: 50px;
    }
}
.max-100{
    max-width: 100px;
    @media (max-width: 650px) {
      max-width:100%;
    }
}

.max-150{
    max-width: 150px;
}
.max-160{
    max-width: 150px;
}
.max-200{
    max-width: 200px !important;
 
}
.max-300-computer{
    max-width: 300px !important;
    @media (max-width: 750px) {
      max-width:100% !important
    }
}
.max-300{
    max-width: 300px !important;
}
.max-400{
    max-width: 400px !important;
}
.max-500{
    max-width: 500px !important;
}
.max-600{
    max-width: 600px;
}
.max-800{
    max-width: 800px;
}

.max-900{
    max-width: 900px;
}


.max-1000{
    max-width: 1000px;
}

.max-1100{
    max-width: 1100px;
}

.max-1200{
    max-width: 1200px !important;
}

.max-1400{
    max-width: 1400px;
}

.max-80p{
    max-width: 80%;
}

.max-90p{
    max-width: 90%;
}

.max-95p{
    max-width: 95%;
}

.relative {
 
position: relative;

}


.full-width {
 
         width: 100% !important;
         max-width: 100%;
   
}

.full-width-mobile{
    @media (max-width: 750px) {
        max-width: 100% !important;
    }
}

.auto-width{
    width: auto !important ;
}

.self-center{
    align-self: center;
}

.prev-slider-button, .next-slider-button{
    
    background: rgb(0,120,170);
    color: #fff;
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50%;
    top: 120px;
  
    position: absolute;
    z-index:10000;
    display: flex ;
    align-items: center;
    justify-content: center;
    padding: 0px;

    >svg{
        color: #fff;
        font-size: 18px; 
    }
 
}

.prev-slider-button{
    left: -10px;
}

.next-slider-button{
    right: -10px;
 
}

.fixed-150{
    >div, >aside, >label, >button, >a {
        width: 150px;
        min-width: 150px;
    }
}

.fixed-200{
    >div, >aside, >label, >button, >a {
        width: 200px;
        min-width: 200px;
    }
}

.fixed-250{
    >div, >aside, >label, >button, >a {
        width: 250px;
        min-width: 250px;
    }
}

.fixed-300{
    >div, >aside, >label, >button, >a {
        width: 300px;
        min-width: 300px;
    }
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.gap-xs{
    gap: 7.5px;
}

.gap-sm{
    gap: 15px;
    @media (max-width: 750px) {
      gap: 7.5px;
    }
}

.gap-md{
    gap: 20px;
}

.gap{
    gap: 30px;
    @media (max-width: 750px) {
      gap: 15px;
    }

}

.gap-bg{
    gap: 45px;
}

.border {
    border: 1px solid #ccc;
}

.border-none{
    border: 0px solid #ccc;
}

.border-radius-sm{
    border-radius: 7.5px;
}

.border-radius{
    border-radius: 15px;
}

.pad-xs{
    padding: 7.5px !important;
}

.pad-sm{
    padding: 15px;
}

.pad-mobile{
  @media (max-width: 1200px) {
        padding: 0px  50px !important;
    }
}

.pad{
    padding: 30px;
   
}

.pad-bg{
    padding: 45px;
    @media (max-width: 750px) {
      padding:15px;
    }
}

.pad-x-xs{
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.pad-x-sm{
    padding-left: 15px;
    padding-right: 15px;
}

.pad-x{
    padding-left: 30px;
    padding-right: 30px;
}

.pad-x-bg{
    padding-left: 45px;
    padding-right: 45px;
}

.pad-y-xs{
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.pad-y-sm{
    padding-top: 15px;
    padding-bottom: 15px;
}

.pad-y{
    padding-top: 30px;
    padding-bottom: 30px;
}

.pad-y-bg{
    padding-top: 45px;
    padding-bottom: 45px;
}

.height-85{
    height: 85vh;
}

.white{
    background: #fff;
}
 
.overflow-y-auto{
    overflow-y:auto ;
}


 

.container-pad{
    
 

    @media (max-width: 750px) {
        padding:  30px;
    }

}



.slick-slider{
  width: 100% ; 
  
>button{
  top: 100px !important;
}
 
}
 
.slick-slide{
    width: 100%;
}

.slick-dot{
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgba(0,0,0,0.6);
  display: flex ;
  align-items: center;
  justify-content: center;
  >p{
    color: #ddd;
    font-size: 11px;
  }
  cursor: pointer;
}

.slick-dots{
  display: flex ;
  align-items: center;
  gap: 5px;
  justify-content: center;
  align-items: center;
 margin-bottom: 0px;
 
 @media (max-width: 750px) {
  margin-bottom: 10px;
    }
 
 
}

.slick-dot.slick-active{
  opacity: 1 ;
  background: #f8cd18 ;
  >p{
    color: #333;
  }
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  border-radius: 50%;
  position: absolute ;
 
  &:before{
    color: #00aaef;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  border-radius: 50%;
  position: absolute ;
 
  z-index: 1;
  &:before{
    color:  #00aaef;
    font-size: 40px;
    opacity: 1 ;
  }
}


.multipleSlider{
    .slick-track{
    width: 100% !important ;
    
}
}

.swiper-horizontal{
    width: 100%;
    margin: 0px;
}



.full-height {
    min-height: 100vh;
}
.widescreen {
    width: 100%;
    padding-bottom: 56.25%;
}
.row {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.row-space-between{
    justify-content: space-between;
}

.column {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.column-to-reverse {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 450px) {
      flex-direction: column-reverse;
    }

}
.row-to-column {

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
    justify-content: flex-start;
    }

}

.row-to-column-reverse {

display: flex;
align-items: center;
justify-content: center;

@media (max-width: 750px) {
    flex-direction: column-reverse;
    align-items: center;
justify-content: flex-start;
}

}

.align-start{
    align-items: flex-start;
}
 
.flex-wrap{
    flex-wrap: wrap;
}

.stretch {
    align-self: stretch;
}

.row-start{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.row-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.column-start{
    align-items: flex-start;
    justify-content:flex-start ;
}

.column-end{
    align-items: flex-end;
    justify-content: center;
}


.row-to-column-start{
    align-items: center;
    justify-content: flex-start;
  

    @media (max-width: 750px) {
        align-items: flex-start;
    justify-content: center;
    }
}

.align-start{
    align-items: flex-start;
}
 

.row-space-between{
    display: flex ;
    align-items: center;
    justify-content: space-between;
}

.margin-right-sm{
    margin-right: 15px;
}

.overflow-hidden-height{
    overflow-y: auto ;
    max-height: 70vh;
}

.overflow-x-hidden{
    overflow-x: hidden ;
   
}


.input-xs {
    .inputContainer {
        min-height: 30px !important;
        padding: 0px;

        input{
            padding: 7.5px !important;

        }
    }
}

.icon-button {
    min-width: 46px ;
    min-height: 46px ;
    width: 20px ;
    height: 20px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    &:hover{
        svg{
            transform: scale(1.2) rotate(90deg);
        }
    }

}

.filter-icon-position{
    position: absolute;
    top: 7.5px ;
    right: 7.5px;
    opacity: 0.3;
    width: 10px;
    height: 10px;
    cursor: pointer ;

    &:hover{
        opacity: 1;
        color: #405cf5;
        transform: scale(1.2) ;
    }

}

.icon-button-xs {
    min-width: 30px ;
    min-height: 30px ;
    width: 30px ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    

    &:hover{ 
        svg{
            transform: scale(1.2);
        }
    }

}

.icon-button-xs-width {
    min-width: auto ;
    min-height: 30px ;
    width: auto ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;
    font-size: 14px;
    gap: 7.5px;


    p{
        font-size: 14px; 
    }

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    &:hover{
        svg{
            transform: scale(1.2) ;
        }
    }

}

.soft-button {

    background-color: #fafafa;
    color: #aaa;

    &:hover{
        background-color: #405cf5;
    color: #fff;
    }

}

.medal{
    width: 15px;
    height: 15px;
  
    padding: 0px;
    display: flex ;
    align-items: center;
    justify-content: center;
    border:0px;
    background: none;
    >svg{
        width: 15px;
        height: 15px;
    }
}

.highlightTitle{
    font-size: 16px;
    font-weight: bold;
   
    width: auto;
    align-self: flex-start;
    color: #777 !important;
    padding: 7.5px 15px;
    padding-left: 0px;
    margin-bottom: 15px;
    border-radius: 6px;

     
}



.computerOnly{
display: block !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.computerOnlyFlex{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: block !important;
  }
}

.mobileOnlyFlex{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.titleColor{
    color: #3e77a2;
}

.color-button {
    background-color: #405cf5;
    color: #fff;
}

.button-xxs {
     
    min-height: 30px ;
    width: auto ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    

    &:hover{ 
        svg{
            transform: scale(1.2);
        }
    }

}

.rotate180{
    transform: rotate(180deg) ;
}

.checkboxIcon{
    min-width: 15px;
    min-height: 15px;
}

.text-title {
    font-size: 56px;
    font-weight: 700;
    line-height: 56px;

    @media (max-width: 750px) {
        font-size:  32px;
 
    line-height:  32px;
    }
   
}

.text-subtitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: 750px) {
        font-size:  28px;
 
    line-height:  28px;
    }
}

.text-paragraph {
    font-size: 18px;
    line-height: 1.5;
}

.text-center{
    text-align: center !important;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}

.text-justify{
    text-align: justify;
}





.inputContainer{
position: relative ;

    .inputPlaceholder{
     position: absolute ;
     top: -8px;
     background: #fff ;
     font-size: 11px;
     margin: 0px 15px;
 }
}

.filterButton {
    font-size: 12px;
    border: 0px;
}



.dark-theme{
    background: #333;

    p,h2,h1{
        color: #fff;
    }

    input {
        background: none ;
        color: #fff;
        &::placeholder {
        color: #ddd;
      
    }
    }

    tr{
        background: #333 ;
        color: #fff;

        &:hover{
            background: #444 ;  
        }
    }
    .table-header{
        background: #555;
         color: #fff;
         th{
            background: #555;
         }
        
    }
}

.thumb{
  background-position: top center !important;
  background-size: cover !important;
}

.pointer{
  cursor: pointer ;
}

.grow{
  transition: 0.5s all;
  &:hover{
  transform: scale(1.1) ;
  }
}


.greenButton2{
    padding: 10px 20px;
    background: #fcc010;
    color: #fff;
    border: 0px;
    border-radius: 2px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;
    font-family:'GothamBlack',Helvetica,  sans-serif !important;
    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }

  .greenButton2Reverse{
    padding:  20px;
    color: #fcc010;
    background: #fff;
    border: 0px;
    border-radius: 30px;
    font-family:'GothamBlack',Helvetica,  sans-serif !important;
    transition: 0.4s all;
    font-weight: bold;

    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }
  .truncate{
    overflow: hidden;
     line-clamp: 3;
     max-height: 120px;
     min-height: 120px;
  }

  .greenButton2Big{
    padding: 20px 30px;
    background: #fcc010;
    color: #fff;
    border: 0px;
    border-radius: 70px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;
    font-family:'GothamBlack',Helvetica,  sans-serif !important;
    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;
    font-size: 42px;
    @media (max-width: 750px) {
      padding: 10px 15px;
      font-size: 28px;
  }
  
  }


  .white{
    color: #fff !important;
  }

  .space-between { justify-content:  space-between;}
`;
