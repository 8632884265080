import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';



import Home from '../pages/Home';
import Dicas from '../pages/Dicas';
import ComoEFeito from '../pages/ComoEFeito';
import Vitalidade from '../pages/Vitalidade';
import VitalidadeV2 from '../pages/VitalidadeV2';
import Muscular from '../pages/Muscular';
import MuscularV2 from '../pages/MuscularV2';
import DesempenhoMaximo from '../pages/DesempenhoMaximo';
import DesempenhoMaximoV2 from '../pages/DesempenhoMaximoV2';
import Nutrientes from '../pages/Nutrientes';
import HomeV3 from '../pages/HomeV3';
const ValidPaths = {

  Home,

};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route

        path="/"
        exact
        component={HomeV3}
      />
      <Route

        path="/v3"
        exact
        component={HomeV3}
      />
      <Route

        path="/dicas"

        component={Dicas}
      />

      <Route

        path="/como-e-feito"

        component={ComoEFeito}
      />

      <Route

        path="/vitalidade"

        component={Vitalidade}
      />
      <Route

        path="/vitalidade-v2"

        component={VitalidadeV2}
      />
      <Route

        path="/massa-muscular"

        component={Muscular}
      />

      <Route

        path="/massa-muscular-v2"

        component={MuscularV2}
      />

      <Route

        path="/desempenho-maximo-v2"

        component={DesempenhoMaximoV2}
      />
      <Route

        path="/desempenho-maximo"

        component={DesempenhoMaximo}
      />

      <Route

        path="/nutrientes"

        component={Nutrientes}
      />

    </Switch>
  );
};

export default Routes;
