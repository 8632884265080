export const hash = '1ad2a9d8-1dd5-4352-8e46-b9842164780d';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6LdoQWIeAAAAAP457cno1Yt_wU6F2dbJxz-M_vCY';
export const apiUrl = `https://api-global-v3.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br';



//export const apiSocket = 'http://localhost:8080';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://leiteproteico.ccgl.com.br';
export const supportNumber = `555195181533`;
export const home = 'Home';
export const haveTranslate = false;
export const defaultProjectHash = 'leite-proteico';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'CCGL - Leite Proteico';
export const logo = '/assets/logo.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'CCGL - Leite Proteico';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  spotify: '',
  twitter: '',
};


export const defaultTheme = {
  defaultBackground: '#364c95',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: '#364c95',
    backgroundMobile: '#fff',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
