import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,

} from './styles';

import CafeDaManha from './modules/CafeDaManha';

import { useAuth } from '../../hooks/Auth';

import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';

import api from '../../services/api';
import { hash, urlWebsite } from '../../config';

import { loadFromJson } from '../../utils/loadFromJson';
import { relative } from 'path';
import Slider from 'react-slick';
import LanchesIntermediarios from './modules/LanchesIntermediarios';
import Almoco from './modules/Almoco';
import Jantar from './modules/Jantar';
import Receitas from './modules/Receitas';

import { scrollToID } from '../../utils/scrollTo';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Dicas: React.FC = () => {

  const { setMenu } = usePages();
  const { setAvailableLanguages } = useLanguage();
  const { translate } = useLanguage();
  const lorem = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
  const { addModal, removeModal } = useModal();
  const [slides, setSlides] = useState<Array<JSX.Element>>([]);
  const { user } = useAuth();

  const [search, setSearch] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const sendToEvents = () => {

    let link = currentCategory ? `/${currentCategory}` : `/eventos`;

    if (search) {
      link = `${link}?search=${encodeURIComponent(search)}`;
    }

    window.location.href = link;

  }

  useEffect(() => {

    if (currentCategory) {
      window.location.href = currentCategory ? `/${currentCategory}` : `/eventos`;
    }

  }, [currentCategory])

  const loadConfig = async () => {
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }


    if (data.slides) {
      const slideJSX: Array<JSX.Element> = [];

      data.slides.map(s => {
        slideJSX.push(s.link_url ? <a href={s.link_url}><img loading='lazy' src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} /></a> : <img loading='lazy' src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} />)
      })

      setSlides(slideJSX);

      if (data?.popup_status === 'yes' && data?.popup_images && data?.popup_images?.length > 0) {

        if (user && user.updated_information !== 'yes' && user.profile === 2) {

        }
        else {
          addModal({
            key: 'popup',
            theme: 'whiteModalCapa',
            title: ' ',
            content: <><div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>
              <h2 style={{ padding: '10px', width: '100%', textAlign: 'center', color: 'rgb(0,14,64)' }}>Clique na imagem para acessar</h2>
              {data?.popup_images.map(popup => {
                return <a onClick={() => removeModal('popup')} key={`popup-url-${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '15px auto' }} href={`${popup?.link_url}`}>
                  <img loading='lazy' src={`${urlWebsite}/${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }} />
                </a>

              })}

            </div></>
          })

        }


      }

    }

    const menu: Array<IMenu> = [];

    try {
      data?.menu.map((m: IMenu) => {
        m.fluid = parseInt(m.fluid.toString(), 10);
        m.status = parseInt(m.status.toString(), 10);
        m.external = parseInt(m.external.toString(), 10);

        menu.push(m);
      })

      setMenu(menu);

    }
    catch (err) {
      console.log('Error on define Menu');
    }


    setAvailableLanguages(data?.languages || []);




  }

  useEffect(() => {
    loadConfig();
    /*
        addModal({
       key:'popup',
       theme: 'whiteModal2',
       title:' ',
       content: <><div style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}>
         <Link onClick={() => removeModal('popup')} style={{width:'100%', maxWidth:'500px', margin: '0px auto'}} to="/app/sessao-clinica-hospitais-dasa-brasil/login">
           <img  loading='lazy' src="/apoio/sessao-clinica.png" style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}/>
         </Link>
         </div></>
     }  ) */
  }, [])


  const handle = () => { }




  return (<>






    <div className='full-width pad' id="rotina" style={{ background: 'rgba(0,0,0,0.4)' }} tabIndex={0}>
      <Container className='gap-bg column fadeIn' style={{ minHeight: '0vh', background: 'none', zIndex: 900 }}>
        <h2 className='hugeTitle2 colorWhite text-center ' style={{ margin: '0px 0px 0px 0px' }}>
          E COMO  <strong className='color2'>INSERIR </strong>
          NA SUA ROTINA?

        </h2>
        <div className='max-1000 gap column'>

          <div className='row  row-start max-1200 gap-md'>

            <p className='contentParagraphSimple  max-1200 midTitle2'>

              Ao longo de todo o seu dia, em diferentes tipos de receitas, para adicionar uma dose extra de proteína e outros nutrientes a sua rotina!
            </p>
          </div>

          <div className='full-width computerOnly ' style={{ position: 'relative' }}>
            <div className='cursor  scaleOnHover' style={{
              width: '18%', height: '37%',

              position: 'absolute',
              top: '8%',
              left: '6%'
            }}
              title='Café da Manhã' onClick={() => scrollToID('cafe-da-manha')}
            >
              <img loading='lazy' src='/apoio/v3/cafe2.png' className='full-width' />
            </div>

            <div className='cursor  scaleOnHover' style={{
              width: '18%', height: '37%',

              position: 'absolute',
              top: '10%',
              left: '33.5%'
            }}
              title='Almoço' onClick={() => scrollToID('almoco')}
            >
              <img loading='lazy' src='/apoio/v3/almoco3.png' className='full-width' />
            </div>


            <div className='cursor  scaleOnHover' style={{
              width: '18%', height: '37%',

              position: 'absolute',
              top: '13%',
              left: '65.5%'
            }}
              title='Jantar' onClick={() => scrollToID('jantar')}
            >
              <img loading='lazy' src='/apoio/v3/jantar3.png' className='full-width' />
            </div>

            <div className='cursor  scaleOnHover' style={{
              width: '21%', height: '37%',

              position: 'absolute',
              top: '41%',
              left: '18%'
            }}
              title='Lanches Intermediários' onClick={() => scrollToID('lanches-intermediarios')}
            >
              <img loading='lazy' src='/apoio/v3/alimentacao-lanches1.png' className='full-width' />
            </div>


            <div className='cursor scaleOnHover' style={{
              width: '21%', height: '37%',

              position: 'absolute',
              top: '46%',
              left: '47.3%'
            }}
              title='Lanches Intermediários' onClick={() => scrollToID('lanches-intermediarios')}
            >
              <img loading='lazy' src='/apoio/v3/lanches3.png' className='full-width' />
            </div>
            <img loading='lazy' src='/apoio/v3/fundo-alimentacao.jpeg' className='full-width' />


          </div>





        </div>



      </Container>


    </div>




    <Container tabIndex={0} id='cafe-da-manha' className=' column ' style={{ minHeight: '30vh', padding: '100px 50px', background: 'none', zIndex: 900 }}>
      <h2 className='hugeTitle2 text-center colorWhite'>
        CAFÉ DA MANHÃ
      </h2>


      <br /><br />
      <p className='contentParagraphSimple  max-1200 midTitle2'>

        <strong>Mas como o nosso NOVO LEITE EM PÓ pode estar inserido nessa refeição? </strong>
      </p>



      <br /><br />
      <div className='full-width max-2000 gap row-to-column'>

        <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
          {CafeDaManha()}
        </div>
        <div className='full-width  mobileOnly'>
          <Slider autoplay
            responsive={[

              {
                breakpoint: 1200,
                settings: {

                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
            {CafeDaManha()}
          </Slider>
        </div>
      </div>



    </Container>

    <div tabIndex={0} className='full-width pad' style={{ background: 'rgba(0,0,0,0.4)' }}>
      <Container id='lanches-intermediarios' className=' column ' style={{ minHeight: '30vh', padding: '50px 50px', background: 'none', zIndex: 900 }}>
        <h2 className='hugeTitle2  text-center  colorWhite'>
          LANCHES INTERMEDIÁRIOS
        </h2>






        <br /><br />
        <div className='full-width max-2000 gap row-to-column'>

          <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
            {LanchesIntermediarios()}
          </div>
          <div className='full-width  mobileOnly'>
            <Slider autoplay
              responsive={[

                {
                  breakpoint: 1200,
                  settings: {

                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 900,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 750,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 450,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
              {LanchesIntermediarios()}
            </Slider>
          </div>
        </div>



      </Container>
    </div>
    <Container tabIndex={0} id='almoco' className=' column ' style={{ minHeight: '30vh', padding: '100px 50px', background: 'none', zIndex: 900 }}>
      <h2 className='hugeTitle2  text-center  colorWhite'>
        ALMOÇO
      </h2>


      <br /><br />


      <div className='full-width max-2000 gap row-to-column'>

        <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
          {Almoco()}
        </div>
        <div className='full-width  mobileOnly'>
          <Slider autoplay
            responsive={[

              {
                breakpoint: 1200,
                settings: {

                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}

            slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
            {Almoco()}
          </Slider>
        </div>
      </div>



    </Container>
    <Container tabIndex={0} id='jantar' className=' column ' style={{ minHeight: '30vh', padding: '100px 50px', background: 'rgb(0,0,0,0.3)', zIndex: 900 }}>
      <h2 className='hugeTitle2  text-center  colorWhite'>
        JANTAR
      </h2>

      <br /><br />


      <div className='full-width max-2000 gap row-to-column'>

        <div className='full-width row row  gap computerOnlyFlex' style={{ alignItems: 'flex-start' }}>
          {Jantar()}
        </div>
        <div className='full-width  mobileOnly'>
          <Slider autoplay
            responsive={[

              {
                breakpoint: 1200,
                settings: {

                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}

            slidesToShow={1} slidesToScroll={1} infinite arrows={false}>
            {Jantar()}
          </Slider>
        </div>
      </div>



    </Container>
    <Receitas />

  </>
  );
};
export default Dicas;
